<script>
import primeLogo from '@/assets/prime.png';

export default {
    name: 'PacklistUpsell',
    props: {
        upsellProducts: {
            type: Array,
            default: () => []
        },
        packlistName: {
            type: String,
            default: null
        },
        currentFyler: {
            type: Object,
            default: null
        },
        currentPackstats: {
            type: Object,
            default: () => ({
                progress: 0,
                targetProgress: 40
            })
        },
        currentPacklistItem: {
            type: Object,
            default: null
        },
        currentPrinter: {
            type: Object,
            default: null
        },
        openItems: {
            type: Array,
            default: () => []
        },
        isFullscreen: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            primeLogo,
            currentTime: Date.now(),
            sessionTimer: null
        };
    },
    computed: {
        hasUpsellProducts() {
            return this.upsellProducts && this.upsellProducts.length > 0;
        },
        customer() {
            return this.currentPacklistItem?.customer || {};
        },
        isPrime() {
            return this.currentPacklistItem?.shipping?.type === 'PRIME';
        },
        getProgressColor() {
            return this.currentPackstats.progress <= this.currentPackstats.targetProgress ? 'success' : 'error';
        },
        sessionTimeElapsed() {
            if (!this.currentPackstats.packStart) return '00:00';
            
            const now = new Date(this.currentTime);
            const packStart = new Date(this.currentPackstats.packStart);
            const elapsed = Math.floor((now - packStart) / 1000);
            return this.formatTime(elapsed);
        },
        calculatePacksPerHour() {
            if (!this.currentPackstats.packStart) return 0;
            
            const now = new Date(this.currentTime);
            const packStart = new Date(this.currentPackstats.packStart);
            const elapsed = (now - packStart) / 1000;
            if (elapsed <= 0) return 0;
            
            const packs = this.currentPackstats.count;
            const packsPerHour = packs / (elapsed / 3600);
            return Math.round(packsPerHour);
        }
    },
    mounted() {
        this.startSessionTimer();
    },
    beforeDestroy() {
        this.stopSessionTimer();
    },
    methods: {
        formatTime(seconds) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;
            return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
        },
        formatStartTime(timestamp) {
            if (!timestamp) return '';
            const date = new Date(timestamp);
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${hours}:${minutes} Uhr`;
        },
        handlePrinterDialog() {
            this.$emit('printer-dialog');
        },
        handleFullscreen() {
            this.$emit('fullscreen');
        },
        startSessionTimer() {
            this.sessionTimer = setInterval(() => {
                this.currentTime = Date.now();
            }, 1000);
        },
        stopSessionTimer() {
            if (this.sessionTimer) {
                clearInterval(this.sessionTimer);
                this.sessionTimer = null;
            }
        }
    }
}
</script>

<template>
    <div>
        <!-- Products and Address Row -->
        <div class="d-flex gap-4 justify-space-between pa-3 white" v-if="upsellProducts.length > 0 || currentFyler">
            <!-- Products Section (Left) -->
            <div class="d-flex gap-4 products-upsell-list">
                <!-- Upsell Products -->
                <v-card
                    v-for="product in upsellProducts"
                    :key="product.sku"
                    class="product-card pa-1 flex-shrink-1"
                    :style="{ width: `${100 / Math.min(upsellProducts.length, 8)}%`, aspectRatio: '1/1' }"
                    elevation="2"
                >
                    <v-img
                        :src="`/api/files/sku?sku=${product.sku}`"
                        contain
                        class="product-image"
                        height="100%"
                        width="100%"
                    >
                        <v-badge
                            v-if="product.qty > 1"
                            :content="`Menge: ${product.qty}`"
                            color="error"
                            class="quantity-badge"
                            location="top end"
                            offset-x="-4"
                            offset-y="8"
                        ></v-badge>
                    </v-img>  
                </v-card>
                
                <!-- Flyer Card -->
                <v-card 
                    v-if="currentFyler"
                    class="product-card pa-1 flex-shrink-0"
                    :style="{ width: `${100 / Math.min(upsellProducts.length, 8)}%`, aspectRatio: '1/1', backgroundColor: currentFyler?.bg, color: currentFyler?.color }"
                    elevation="2"
                >
                    <v-img
                        :src="currentFyler.url"
                        contain
                        height="100%"
                        width="100%"
                    ></v-img>
                </v-card>
                
                <!-- Fallback message when no upsell products -->
                <div v-if="!hasUpsellProducts && !currentFyler" class="no-upsell">
                    <span class="text-caption no-upsell-text">Keine Zusatzprodukte verfügbar</span>
                </div>
            </div>

            <!-- Address Section (Right) -->
            <div class="address-section" v-if="currentPacklistItem" style="opacity: 0;">
                <v-card elevation="2" class="h-100 grey lighten-4">
                    <v-card-text class="text-subtitle-1 pa-2">
                        <div class="customer-name text-truncate green--text text--darken-1 font-weight-bold">
                            {{ customer.firstName }} {{ customer.lastName }}
                        </div>
                        <div class="customer-address text-truncate">
                            {{ customer.adress }}
                        </div>
                        <div v-if="isPrime">
                            <img class="w-50" :src="primeLogo" alt="Prime Logo">
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </div>

        <!-- Enhanced Time Statistics Section -->
        <div class="time-stats-container">
            <div class="packlist-header mb-2 d-flex align-center justify-space-between">
                <div>
                    <v-chip color="primary" small>{{ packlistName }}</v-chip>
                    <span class="ml-2 text-body-2">{{ openItems.length }} offen</span>
                </div>
                <div class="d-flex align-center">
                    <v-chip v-if="currentPrinter" size="x-small" outlined color="grey" class="mr-2">
                        <v-icon x-small left>mdi-printer</v-icon>
                        {{ currentPrinter.name }}
                    </v-chip>
                    <v-btn icon x-small @click="handlePrinterDialog" class="mr-1">
                        <v-icon small>mdi-cog</v-icon>
                    </v-btn>
                    <v-btn icon x-small @click="handleFullscreen">
                        <v-icon small v-if="isFullscreen">mdi-fullscreen-exit</v-icon>
                        <v-icon small v-else>mdi-fullscreen</v-icon>
                    </v-btn>
                </div>
            </div>
            <div class="time-tracking d-flex align-center">
                <v-progress-linear
                    :value="Math.min((currentPackstats.progress / currentPackstats.targetProgress) * 100, 100)"
                    :color="getProgressColor"
                    height="20"
                    class="time-progress"
                    :class="{ 'pulse': currentPackstats.progress > currentPackstats.targetProgress }"
                    style="width: 120px;"
                >
                    <template v-slot:default>
                        <span class="white--text font-weight-medium">{{ formatTime(currentPackstats.progress) }}</span>
                    </template>
                </v-progress-linear>
                <div v-if="currentPackstats.packStart" class="session-time-container mx-2">
                    {{ sessionTimeElapsed }}
                </div>
                <v-divider vertical class="mx-2 my-0" style="height: 24px"></v-divider>
                <div class="stats-inline">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip size="x-small" color="success" v-bind="attrs" v-on="on" class="mr-2">
                                <v-icon x-small left>mdi-timer</v-icon>
                                {{ formatTime(currentPackstats.time) }}
                            </v-chip>
                        </template>
                        <span>Durchschnittliche Packzeit</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip size="x-small" color="info" v-bind="attrs" v-on="on">
                                <v-icon x-small left>mdi-package-variant</v-icon>
                                {{ currentPackstats.count }}
                            </v-chip>
                        </template>
                        <span>Pakete heute verpackt</span>
                    </v-tooltip>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.gap-4 {
    gap: 16px;
}

.products-upsell-list {
    min-width: 0;
    flex-wrap: nowrap;
    width: 80%;
}

.product-card {
    transition: transform 0.2s ease;
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    min-width: 80px;
    max-width: 120px;
    position: relative;
}

.product-card:hover {
    transform: scale(1.05);
}

.product-image {
    object-fit: contain;
    background-color: white;
    height: 100%;
    width: 100%;
}

.quantity-badge :deep(.v-badge__content) {
    font-size: 12px;
    padding: 4px 8px;
    min-width: auto;
    height: auto;
}

.no-upsell {
    position: relative;
    padding: 0.75rem;
    background-color: #f5f5f5;
    border-radius: 4px;
    text-align: center;
    color: #757575;
    width: 100%;
}

.no-upsell-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.address-section {
    background-color: #eee;
    width: 20%;
}

.h-100 {
    height: 100%;
}

.w-50 {
    width: 50%;
}

.customer-name, .customer-address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.customer-name {
    margin-bottom: 4px;
}

.time-stats-container {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8px 16px;
    background-color: #f5f5f5;
    border-radius: 0 0 4px 4px;
}

.time-progress {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.stats-inline {
    display: flex;
    align-items: center;
}

.session-time-container {
    background-color: #3949ab;
    color: white;
    border-radius: 4px;
    padding: 4px 8px;
    font-weight: 600;
    font-size: 0.9rem;
    min-width: 60px;
    text-align: center;
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);
}

.printer-chip {
    transition: all 0.2s ease;
}

/* Pulsing animation for when time exceeds target */
.pulse {
    animation: pulse-effect 1.5s infinite;
}

@keyframes pulse-effect {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.7;
    }
    100% {
        opacity: 1;
    }
}
</style>