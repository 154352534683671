<script>
import MessageComponent from '@/components/Messaging/MessageComponent.vue';
import SimpleLoader from '@/components/Loading/SimpleLoader.vue';
import primeLogo from '@/assets/prime.png';

import EventBus from "@/event-bus";

//Packlist Related Components
import PacklistItems from './PacklistItems.vue';
import PacklistService from '@/services/PacklistService.js';
import AuthService from '@/services/AuthService.js';
import PacklistUpsell from './PacklistUpsell.vue';
import PacklistOrder from './PacklistOrder.vue';
import PacklistActions from './PacklistActions.vue';
import PacklistModals from './PacklistModals.vue';
import DamagedDialog from '../packlist_v2/DamagedDialog.vue';
import PackagingService from '@/services/PackagingService.js';

export default {
  name: 'PacklistRoot',
  components: {
    MessageComponent,
    SimpleLoader,
    PacklistItems,
    PacklistUpsell,
    PacklistOrder,
    PacklistActions,
    PacklistModals,
    DamagedDialog
  },
  data() {
    return {
        messageData: null,
        loadingText: 'Lädt Packliste...',
        loading: true,
        packlistItems: null,
        currentOrderId: null,
        currentPacklistItemId: null,
        currentPacklistItemUserLaser: null,
        currentPacklistItem: null,
        packagingSelection: [],
        skuBlackList: [],
        flyerMapping: [],
        primeLogo,
        currentPackstats: {
          progress: 0,
          targetProgress: 40,
          count: 0,
          time: 0,
          packStart: null,
          packEnd: null
        },
        printers: [],
        printButtonPressed: false,
        currentPrinter: null,
        printerDialog: false,
        isPrinting: false,
        user: null,
        dialogs: {
          multicartShoppingList: false,
          multicartWarning: false,
          multicartButReadyWarning: false,
          problem: false,
          warranty: false
        },
        multiCartItems: [],
        currentScrollIndex: 0,
        packlistName: null,
        startTime: null,
        selectedPrinterIndex: null,
        isFullscreen: false,
        isScrolledToBottom: false,
        multiCartPayload: null,
        multiCartSlotDialog: false,
        isProcessingMultiCart: false,
        isRequestingMultiCartSlot: false,
        manualMultiCartAction: {
          dialog: false,
          location: null,
          items: [],
          options: ["A1", "A2", "A3", "A4", "A5", "A6", "A7", "A8", "B1", "B2", "B3", "B4", "B5", "B6", "B7", "B8", "C1", "C2", "C3", "C4", "C5", "C6", "C7", "C8", "D1", "D2", "D3", "D4", "D5", "D6", "D7", "D8", "E1", "E2", "E3", "E4", "E5", "E6", "E7", "E8"]
        }
    }
  },
  computed: {
    openItems() {
        return this.packlistItems.filter(item => item.status === 'open');
    },
    pausedItems() {
        return this.packlistItems.filter(item => item.status === 'paused');
    },
    packedItems() {
        return this.packlistItems.filter(item => item.status === 'packed');
    },
    upsellProducts() {
      if (!this.currentPacklistItem?.products) return [];
      
      const upsellItems = this.currentPacklistItem.products.filter(
        product => (product.upsell === true && !product.isEvent) || this.skuBlackList.includes(product.sku)
      );

      const duplicatedProducts = upsellItems.reduce((acc, product) => {
        const existingProduct = acc.find(p => p.sku === product.sku);
        
        if (existingProduct) {
          existingProduct.qty = (existingProduct.qty || 1) + (product.qty || 1);
        } else {
          acc.push({
            ...product,
            qty: product.qty || 1
          });
        }
        
        return acc;
      }, []);

      return duplicatedProducts;
    },
    isMulticart() {
      if (!this.currentPacklistItem?.products) return false;
      if (this.packlistName.includes('LS24')) return false;

      return this.currentPacklistItem.products.some(product => product.isMultiCart);
    },
    isShippingReady() {
      if (!this.currentPacklistItem?.products) return false;

      const requiredProducts = this.currentPacklistItem.products.filter(product => 
        !this.skuBlackList.includes(product.sku) && !product.upsell
      );
      if (requiredProducts.length === 0) return true;
      
      return requiredProducts.every(product => product.status === 'ready');
    },
    currentProducts() {
      if (!this.currentPacklistItem?.products) return [];
      
      return this.currentPacklistItem.products.filter(product => {
        const isUpsell = Boolean(product.upsell) === true;
        const isEvent = Boolean(product.isEvent) === true;
        const shouldInclude = (!isUpsell && !isEvent) || (isUpsell && isEvent);
        const notBlacklisted = !this.skuBlackList.includes(product.sku);
        
        return shouldInclude && notBlacklisted;
      });
    },
    isPrinted() {
      if (this.printButtonPressed) {
        return true;
      }

      return false;
    }, 
    isWarranty() {
      if (!this.currentPacklistItem) return false;

      return this.currentPacklistItem.warranty;
    },
    showScrollIndicator() {
      // Only show if we have more than 3 products
      return this.currentProducts.length >= 2 && !this.isScrolledToBottom;
    },
    currentFyler() {
      if (!this.currentPacklistItem) return null;
      
      const orderId = this.currentPacklistItem.orderId;
      const reference = this.currentPacklistItem.reference;
      let value = null;

      if (this.flyerMapping.length === 0) {
        this.buildFlyerMapping();
      }

      if (!reference && orderId) {
        if (orderId.toString().startsWith('MM')) {
          value = this.flyerMapping.find(f => f.value === 'MM');
        } else if (isNaN(orderId) || orderId.toString().startsWith('#')) {
          value = this.flyerMapping.find(f => f.value === 'GF');
        }
      }

      // If external order -> check for reference
      if (reference) {
        if (reference.toString().startsWith('#BP')) {
          value = this.flyerMapping.find(f => f.value === '#BP');
        } else if (reference.toString().startsWith('#FW')) {
          value = this.flyerMapping.find(f => f.value === '#FW');
        }
      }

      return value;
    },
    selectedPrinter() {
      return this.printers[this.selectedPrinterIndex];
    }
  },
  methods: {
    /**
     * Get all Packlist Items
     */
    async getPacklist(packlistName) {
        const response = await PacklistService.getPacklistItems(packlistName);
        
        if (response.status === 200 && response.data.length > 0) {
          if (this.packlistItems) {
              this.packlistItems.splice(0, this.packlistItems.length, ...response.data);
          } else {
              this.$set(this, 'packlistItems', response.data);
          }
        } else if (response.status === 200 && response.data.length === 0) {
            this.$router.push('/');
        } else {
            this.messageData = {
                type: 'alert',
                title: 'Fehler',
                message: 'Konnte Packliste nicht laden',
                timing: {
                    timeStamp: new Date(),
                    lifetime: -1
                },
                reading: {
                    isRead: false,
                    isDismissible: true
                },
                style: {
                    severity: 'error',
                    textColor: 'white'
                },
                actions: []
            }
        }
    },
    /**
     * Get current Packlist Item
     */
    async getCurrentPacklistItem(orderId, id) {
        try {
            const response = await PacklistService.getPacklistItem(orderId);

            if (response.status === 200) {
                this.currentPacklistItem = response.data;
                this.currentPacklistItemId = id;
                //await this.getPackagingSelection();
            } 
        } catch (error) {
            console.error('Error getting current packlist item:', error);
        }
    },
    /**
     * Get Packlist Multi Cart Items
     */
    async getPacklistMultiCartItems(name) {
      let response = await PacklistService.getPacklistMultiCartItems(name);

      if (response.status === 200) {
        this.multiCartItems = response.data;

        if (this.multiCartItems.length > 0) {
          this.dialogs.multicartShoppingList = true;
        }
      }
    },
    async getPackagingSelection() {
      try {
        let skus = this.currentProducts.map(product => product.sku);
        let response = await PackagingService.getPackagingSelection(skus);

        if (response.status === 200) {
          this.packagingSelection = response.data;
          console.log('packagingSelection', this.packagingSelection);
        }
      } catch (error) {
        console.error('Error getting packaging selection:', error);
      }
    },  
    /**
     * Get printers
     */
    async getPrinters() {
      try {
        const response = await PacklistService.getPrinters();
      
        if (response.status === 200) {
          this.printers = response.data;
        }
      } catch (error) {
        console.error('Error getting printers:', error);

        this.messageData = {
          type: 'alert',
          title: 'Fehler',
          message: 'Konnte Drucker nicht laden',
          timing: {
            timeStamp: new Date(),
            lifetime: -1
          },
          reading: {
            isRead: false,
            isDismissible: true
          },
          style: {
            severity: 'error',
            textColor: 'white'
          },
          actions: []
        }
      }
    },
    /**
     * Handle print request
     */
    async handlePrintRequest(isPrinted, under8cm) {
      if (!this.isPrinted) {
        this.isPrinting = true;

        if (!this.currentPrinter) {
          this.printerDialog = true;
          return;
        }

        let response = await PacklistService.print(this.currentOrderId, this.currentPrinter.url, under8cm);

        if (response.status === 200) {
          this.printButtonPressed = isPrinted;
          this.isPrinting = false;
        }
      }
    },
    /**
     * Handle pack request
     */
    async handlePackRequest() {
      try {
        await PacklistService.trackEnd(this.currentPacklistItemId);

        // Store the current index before refreshing
        const currentOrderId = this.currentOrderId;
        const currentIndex = this.packlistItems.findIndex(item => 
          item.id === this.currentPacklistItemId
        );

        // Set loading before refreshing data
        this.loading = true;
        this.loadingText = 'Lädt nächste Bestellung...';

        // Refresh the packlist
        await this.getPacklist(this.packlistName);

        // Reset print button
        this.printButtonPressed = false;

        // Find the next open item after our current position
        let nextItem = null;
        
        // First try to find open items with the same order ID (for multi-item orders)
        const sameOrderOpenItems = this.packlistItems.filter(item => 
          item.status === 'open' && 
          item.orderId === currentOrderId &&
          item.id !== this.currentPacklistItemId
        );
        
        if (sameOrderOpenItems.length > 0) {
          // If there are still open items from the same order, pick the first one
          nextItem = sameOrderOpenItems[0];
        } else {
          // Otherwise, find the next open item in the sequence
          const nextOpenItems = this.packlistItems
            .filter(item => item.status === 'open')
            .filter(item => {
              const itemIndex = this.packlistItems.indexOf(item);
              return itemIndex > currentIndex;
            });
            
          if (nextOpenItems.length > 0) {
            nextItem = nextOpenItems[0];
          } else if (this.openItems.length > 0) {
            // If no next items found, use the first open item as fallback
            nextItem = this.openItems[0];
          }
        }
        
        if (nextItem) {
          // Update properties
          this.currentOrderId = nextItem.orderId;
          this.currentPacklistItemId = nextItem.id;
          this.currentPacklistItemUserLaser = nextItem.userLaser;
          this.currentPacklistItem = null; // Clear first to ensure reactivity
          
          // Get detailed item data
          await this.getCurrentPacklistItem(nextItem.orderId, nextItem.id);
          
          // Reset progress
          this.currentPackstats.progress = 0;
        }

        // Update pack stats
        let newPackSession = {
          userId: this.user.id,
          packStart: new Date()
        };
        localStorage.setItem('lastTimestamp', JSON.stringify(newPackSession));
        
        await this.handlePackstats();
        await this.loadPackStats();
        
        // Finally turn off loading
        this.loading = false;

      } catch (error) {
        console.error('Error packing item:', error);
        this.loading = false;
      }
    },
    handleProblem() {
      this.dialogs.problem = true;
    },
    async openDamagedDialog() {
      EventBus.$emit("OPEN_DAMAGED_DIALOG_RESETONLY", this.currentPacklistItem);
    },
    async handlePushBackOrderRequest() {
      try {
        let response = await PacklistService.pausePacklistItem(this.currentPacklistItemId);

        if (response.status === 200) {
          this.dialogs.multicartWarning = false;
          this.next();
        }
      } catch (error) {
        console.error('Error pushing back order:', error);
      }
    },
    async handleMulticartShelfRequest() {
      if (this.isProcessingMultiCart) return;
      
      try {
        this.isProcessingMultiCart = true;
        this.isRequestingMultiCartSlot = true;

        if (!this.currentPrinter) {
          this.printerDialog = true;
          return;
        }

        const response = await PacklistService.requestMultiCartSlot(
          this.currentOrderId, 
          { printer: this.currentPrinter.url }
        );

        if (response.status === 200) {
          const readyProducts = this.currentProducts.filter(
            p => !p.shelf && p.status === 'ready'
          );

          const relevantPacklistItems = this.packlistItems.filter(item => 
            item.orderId === this.currentOrderId && 
            item.status === 'open'
          );

          const packlistItemIds = relevantPacklistItems.map(item => item.id).filter(Boolean);

          this.multiCartPayload = {
            location: response.data.location,
            items: readyProducts,
            packlistItemIds: packlistItemIds
          };

          this.multiCartSlotDialog = true;
        }
      } catch (error) {
        console.error('Error requesting multicart slot:', error);
        this.messageData = {
          type: 'alert',
          title: 'Fehler',
          message: 'Fehler beim Anfordern eines Multicart-Fachs',
          style: { severity: 'error' }
        };
      } finally {
        this.isProcessingMultiCart = false;
        this.isRequestingMultiCartSlot = false;
      }
    },
    /**
     * Move to next item
     */
    next() {
      const currentItem = this.packlistItems.find(item => 
        item.orderId === this.currentOrderId && 
        item.id === this.currentPacklistItemId
      );
      const currentIndex = this.packlistItems.indexOf(currentItem);
      this.currentScrollIndex = 0;

      if (currentIndex < this.packlistItems.length - 1) {
        const nextItem = this.packlistItems[currentIndex + 1];
        this.handleItemClick(nextItem);
        this.currentPackstats.progress = 0;
      }

      this.handlePackstats();
      this.loadPackStats();
      this.printButtonPressed = false;
    },
    /**
     * Move to previous item
     */
    prev() {
      const currentItem = this.packlistItems.find(item => 
        item.orderId === this.currentOrderId && 
        item.id === this.currentPacklistItemId
      );
      const currentIndex = this.packlistItems.indexOf(currentItem);
      this.currentScrollIndex = 0;
      
      if (currentIndex > 0) {
        const prevItem = this.packlistItems[currentIndex - 1];
        this.handleItemClick(prevItem);
        this.currentPackstats.progress = 0;
      }

      this.handlePackstats();
      this.loadPackStats();
      this.printButtonPressed = false;
    },
    /**
     * Report feedback
     */
    async reportFeedback(message) {
      if (!message) return;

      let response = await PacklistService.sendFeedback(this.currentOrderId, message);

      if (response.status === 200) {
        this.dialogs.problem = false;
      }
    },
    /**
     * Build Sku Black List
     * This is a list of skus that should not be shown normally in the items
     */
    buildskuBlackList() {
      this.skuBlackList = [
        "LYTO_TYPE_C_KABEL",
        "LYTO_NETZSTECKER",
        "LYTO_LED_SOCKEL_WHITE",
        "wooden_led_base_HV",
        "wooden_led_base_RGB",
        "NETZSTECKER",
        "TYP_C_USB_KABEL"
      ];
    },
    /**
     * Build Flyer Mapping
     * This mapping is used to determine which flyer to show based on the order ID or reference
     */
    buildFlyerMapping() {
      this.flyerMapping = [
        {
          value: 'GF',
          label: 'Flyer',
          bg: '#d45355',
          color: '#f9f9f9',
          url: 'https://geschenkfreude.com/cdn/shop/t/19/assets/geschenkfreude-logo.svg?v=152746364003842334961738143808'
        },
        {
          value: 'MM',
          label: 'Flyer',
          bg: '#d0e5de',
          color: '#121212',
          url: 'https://muckelmeister.de/cdn/shop/t/60/assets/logo-white-border.webp?v=4545666206715883731738916651'
        },
        {
          value: '#BP',
          label: 'Flyer',
          bg: '#EBE2D2',
          color: '#121212',
          url: 'https://boandbirdie.com/cdn/shop/files/Bo_Birdie_Lockup_Black.png?v=1714989905&width=1368'
        },
        {
          value: '#FW',
          label: 'Flyer',
          bg: '#E17D63',
          color: '#f9f9f9',
          url: 'https://famwalls.com/cdn/shop/files/famwalls_test_logo.png?v=1637591278&width=200'
        }
      ];
    },
    /**
     * Handle item click
     */
    handleItemClick(item) {
        this.currentOrderId = item.orderId;
        this.currentPacklistItemId = item.id;
        this.currentPacklistItemUserLaser = item.userLaser;
        this.currentPacklistItem = item;
        this.currentPackstats.progress = 0;

        this.handlePackstats();
        this.printButtonPressed = false;
    },
    /**
     * Handle order messaging
     */
    buildOrderMessage(data) {
      this.messageData = data;
    },
    /**
     * Handle printer dialog
     */
    async handlePrinterDialog() {
      await this.getPrinters();
      this.printerDialog = true;
    },
    /**
     * Select printer
     */
    selectPrinter(printer) {
      this.$cookies.set('printer', printer);

      this.currentPrinter = printer;
      this.printerDialog = false;
    },
    /**
     * Update multicart warning dialog state based on conditions
     */
    updateMulticartWarningDialog() {
      // Only show the warning if the shopping list isn't already open
      if (!this.dialogs.multicartShoppingList) {
        this.dialogs.multicartWarning = this.isMulticart && !this.isShippingReady;
      }
    },
    updateMulticartButReadyWarningDialog() {
      // Only show the warning if the shopping list isn't already open
      if (!this.dialogs.multicartShoppingList) {
        this.dialogs.multicartButReadyWarning = this.isMulticart && this.isShippingReady;
      }
    },
    scrollToNextItem() {
      this.currentScrollIndex++;
    },
    handleFullscreen() {
      if (!document.fullscreenElement) {
        this.isFullscreen = true;
        document.documentElement.requestFullscreen();
      } else {
        this.isFullscreen = false;
        document.exitFullscreen();
      }
    },
    async handlePackstats() {
      let currentPackSession = localStorage.getItem('lastTimestamp');

      if (currentPackSession && this.user.id === JSON.parse(currentPackSession).userId) {
        let packSession = JSON.parse(currentPackSession);

        this.currentPackstats.packStart = packSession.packStart;
        console.log('Tracking start existing', this.currentPacklistItemId, packSession.packStart);
        await PacklistService.trackStart(this.currentPacklistItemId, packSession.packStart);
      } else {
        let newPackSession = {
          userId: this.user.id,
          packStart: new Date()
        }

        localStorage.setItem('lastTimestamp', JSON.stringify(newPackSession));
        console.log('Tracking start new', this.currentPacklistItemId, newPackSession.packStart);
        await PacklistService.trackStart(this.currentPacklistItemId, newPackSession.packStart);
      }
    },
    /**
     * Load pack stats from service
     */
     async loadPackStats() {
        try {
            const response = await PacklistService.getPackStats();
            if (response?.data) {
                this.currentPackstats.count = response.data.count;
                this.currentPackstats.time = response.data.time;
            }
        } catch (error) {
            console.error('Error loading pack stats:', error);
        }
    },
    checkScrollPosition() {
      const productCards = document.querySelector('.product-cards');
      if (!productCards) return;
      
      // Get the bounding rectangle of the product cards container
      const rect = productCards.getBoundingClientRect();
      
      // Check if the bottom of the container is visible in the viewport
      // with a small buffer of 100px
      this.isScrolledToBottom = 
        rect.bottom <= window.innerHeight + 100;
    },
    handleKeydown(event) {
      if (this.printerDialog || 
        this.dialogs.multicartShoppingList || 
        this.dialogs.multicartWarning || 
        this.dialogs.multicartButReadyWarning || 
        this.dialogs.problem || 
        this.dialogs.warranty) {
        return;
      }

      if (event.key === 'b') {
          if (!this.isShippingReady) return;
          this.handlePrintRequest(true, false);
      } else if (event.key === 'c') {
          if (!this.isPrinted) return;
          this.handlePackRequest();
      }
    },
    async handleMultiCartSubmit() {
      if (!this.multiCartPayload?.items?.length) {
        this.messageData = {
          type: 'alert',
          title: 'Fehler',
          message: 'Keine Artikel zum Einlagern ausgewählt',
          style: { severity: 'error' }
        };
        return;
      }

      try {
        await PacklistService.putMultiCartItems(
          this.currentOrderId,
          this.multiCartPayload
        );
        
        this.multiCartSlotDialog = false;
        
        // Find current item using both orderId and id
        const currentItem = this.packlistItems.find(item => 
          item.orderId === this.currentOrderId && 
          item.id === this.currentPacklistItemId
        );
        const currentIndex = this.packlistItems.indexOf(currentItem);
        
        await this.getPacklist(this.packlistName);
        
        // Use the more sophisticated approach from handlePackRequest
        let nextItem = null;
        
        // First try to find open items with the same order ID (for multi-item orders)
        const sameOrderOpenItems = this.packlistItems.filter(item => 
          item.status === 'open' && 
          item.orderId === this.currentOrderId &&
          item.id !== this.currentPacklistItemId
        );
        
        if (sameOrderOpenItems.length > 0) {
          // If there are still open items from the same order, pick the first one
          nextItem = sameOrderOpenItems[0];
        } else {
          // Otherwise, find the next open item in the sequence
          const nextOpenItems = this.packlistItems
            .filter(item => item.status === 'open')
            .filter(item => {
              const itemIndex = this.packlistItems.indexOf(item);
              return itemIndex > currentIndex;
            });
            
          if (nextOpenItems.length > 0) {
            nextItem = nextOpenItems[0];
          } else if (this.openItems.length > 0) {
            // If no next items found, use the first open item as fallback
            nextItem = this.openItems[0];
          }
        }
        
        if (nextItem) {
          this.handleItemClick(nextItem);
        }
      } catch (error) {
        console.error('Error putting items in multicart:', error);
        this.messageData = {
          type: 'alert',
          title: 'Fehler',
          message: 'Fehler beim Einlagern der Artikel',
          style: { severity: 'error' }
        };
      }
    }
  },
  watch: {
    currentOrderId: {
      async handler(newVal) {
        if (newVal) {
            this.loading = true;
            this.loadingText = 'Lädt nächste Bestellung...';
            this.messageData = null;
            this.currentPacklistItem = null;
            await this.getCurrentPacklistItem(newVal, this.currentPacklistItemId);
            this.loading = false;
        }
      },
      immediate: true
    },
    isMulticart: {
      handler(newVal) {
        this.updateMulticartWarningDialog();
        this.updateMulticartButReadyWarningDialog();
      },
      immediate: true
    },
    isShippingReady: {
      handler(newVal) {
        this.updateMulticartWarningDialog();
        this.updateMulticartButReadyWarningDialog();
      },
      immediate: true
    },
    isWarranty: {
      handler(newVal) {
        this.dialogs.warranty = newVal;
      },
      immediate: true
    }
  },
  async mounted() {
    this.currentPrinter = this.$cookies.get('printer');

    let packlistName = this.$route.params.id;
    this.packlistName = packlistName;
    await this.getPacklist(packlistName);
    await this.getPacklistMultiCartItems(packlistName);

    // Build the flyer mapping
    this.buildFlyerMapping();

    // Load pack stats
    await this.loadPackStats();

    // Set First Item of openItems as currentOrderId and currentPacklistItem
    if (this.openItems && this.openItems.length > 0) {
      this.currentOrderId = this.openItems[0].orderId;
      this.currentPacklistItemId = this.openItems[0].id;
      this.currentPacklistItemUserLaser = this.openItems[0].userLaser;
      this.currentPacklistItem = this.openItems[0];
    }

    if (this.currentPacklistItem && this.currentPacklistItem.warranty) {
      this.dialogs.warranty = true;
    }

    this.buildskuBlackList();
    this.user = (await AuthService.getUser()).data;
    this.handlePackstats();

    // Keep track of the progress
    setInterval(() => {
      this.currentPackstats.progress++;
    }, 1000);

    this.loading = false;

    // Add a window scroll event listener
    window.addEventListener('scroll', this.checkScrollPosition);
    window.addEventListener('keydown', this.handleKeydown);
    // Also check position initially after a small delay
    setTimeout(this.checkScrollPosition, 500);
  },
  beforeDestroy() {
    if (this.packStatsInterval) {
      clearInterval(this.packStatsInterval);
    }

    // Remove the event listener
    window.removeEventListener('scroll', this.checkScrollPosition);
    window.removeEventListener('keydown', this.handleKeydown);
  }
}
</script>

<template>
  <div>
    <SimpleLoader 
        v-if="loading"
        :loadingText="loadingText"
    />

    <div v-else>
        <PacklistItems 
            :openItems="openItems"
            :pausedItems="pausedItems"
            :packedItems="packedItems"
            :currentOrderId="currentOrderId"
            @item-click="handleItemClick"
        />

        <PacklistUpsell 
            class="sticky-top white"
            :packlistName="packlistName"
            :upsellProducts="upsellProducts"
            :currentFyler="currentFyler"
            :currentPackstats="currentPackstats"
            :currentPacklistItem="currentPacklistItem"
            :currentPrinter="currentPrinter"
            :openItems="openItems"
            :isFullscreen="isFullscreen"
            @printer-dialog="handlePrinterDialog"
            @fullscreen="handleFullscreen"
        />

        <PacklistOrder 
            v-if="currentPacklistItem"
            :order="currentPacklistItem"
            :acsCustomer="currentPacklistItem.acsCustomer"
            :currentScrollIndex="currentScrollIndex"
            :currentProducts="currentProducts"
            @open-damaged-dialog="openDamagedDialog"
            @scroll="checkScrollPosition"
        />

        <PacklistActions 
            class="pos-bottom white"
            :isPrinting="isPrinting"
            :currentPacklistItem="currentPacklistItem"
            :isPrinted="isPrinted"
            :isShippingReady="isShippingReady"
            :isMulticart="isMulticart"
            :customer="currentPacklistItem.customer"
            :blackList="skuBlackList"
            :showScrollIndicator="showScrollIndicator"
            @build-message="buildOrderMessage"
            @print-request="handlePrintRequest"
            @build-problem-modal="handleProblem"
            @handle-pack-request="handlePackRequest"
            @open-multicart-warning="dialogs.multicartWarning = true"
            @scroll-to-next-item="scrollToNextItem"
        />

        <DamagedDialog />
    </div>

    <!-- Printer Dialog -->
    <v-dialog
        v-model="printerDialog"
        max-width="700"
        persistent
        scrollable
    >
        <v-card class="printer-dialog">
            <v-card-title class="headline grey lighten-3 printer-header">
                <v-icon left>mdi-printer</v-icon>
                Drucker auswählen
                <v-spacer></v-spacer>
                <v-btn icon @click="printerDialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="printer-content pt-4">
                <v-list rounded>
                    <v-list-item-group color="primary">
                        <v-list-item
                            v-for="(printer, index) in printers"
                            :key="printer.id"
                            @click="selectPrinter(printer)"
                            class="printer-item"
                            :class="{'selected-printer': selectedPrinter && selectedPrinter.id === printer.id}"
                        >
                            <v-list-item-icon>
                                <v-icon v-if="selectedPrinter && selectedPrinter.id === printer.id">
                                    mdi-printer-check
                                </v-icon>
                                <v-icon v-else>mdi-printer</v-icon>
                            </v-list-item-icon>
                            
                            <v-list-item-content>
                                <v-list-item-title>{{ printer.name }}</v-list-item-title>
                                <v-list-item-subtitle v-if="printer.description">
                                    {{ printer.description }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card-text>
            
            <v-divider></v-divider>
            
            <v-card-actions class="printer-actions py-3 px-4">
                <v-btn 
                    text 
                    color="grey darken-1"
                    @click="printerDialog = false"
                >
                    Abbrechen
                </v-btn>
                
                <v-btn 
                    text 
                    color="info"
                    class="mr-2"
                    @click="$router.push(`/packlistV2/${packlistName}`)"
                >
                    <v-icon left small>mdi-history</v-icon>
                    Zur alten Ansicht
                </v-btn>
                
                <v-spacer></v-spacer>
                
                <v-btn 
                    color="primary" 
                    :disabled="!selectedPrinter"
                    @click="printerDialog = false"
                >
                    <v-icon left small>mdi-check</v-icon>
                    Auswählen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <PacklistModals 
        :dialogs="dialogs"
        :multiCartItems="multiCartItems"
        :currentItem="currentPacklistItem"
        :isMulticart="isMulticart"
        :isShippingReady="isShippingReady"
        :user="user"
        :currentPacklistItemUserLaser="currentPacklistItemUserLaser"
        @reportProblem="reportFeedback"
        @pushBackProducts="handlePushBackOrderRequest"
        @pushToMulticartShelf="handleMulticartShelfRequest"
    />

    <MessageComponent 
        :data="messageData"
        @close-message="messageData = null"
    />

    <!-- Add this before the closing </div> in the template -->
    <v-dialog
      v-model="multiCartSlotDialog"
      max-width="800"
      persistent
    >
      <v-card v-if="multiCartPayload">
        <v-card-title class="headline grey lighten-3">
          <v-icon left>mdi-cart-outline</v-icon>
          Artikel in Warenkorbregal einlagern
          <v-spacer></v-spacer>
          <v-btn icon @click="multiCartSlotDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="4">
              <v-card class="pa-4" color="grey lighten-4">
                <h2 class="text-h4 mb-2">Fach</h2>
                <div class="text-h2 font-weight-bold">
                  {{ multiCartPayload.location }}
                </div>
              </v-card>
            </v-col>

            <v-col cols="8">
              <v-card class="pa-4" color="grey lighten-4">
                <h2 class="text-h6 mb-4">Ausgewählte Artikel</h2>
                <v-list dense>
                  <v-list-item
                    v-for="(item, index) in multiCartPayload.items"
                    :key="index"
                    class="mb-2"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">
                        {{ item.sku }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Menge: {{ item.qty }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox
                        v-model="multiCartPayload.items"
                        :value="item"
                        hide-details
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-4">
          <v-btn
            text
            color="grey darken-1"
            @click="multiCartSlotDialog = false"
          >
            Abbrechen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="isRequestingMultiCartSlot"
            :disabled="!multiCartPayload.items.length || isProcessingMultiCart"
            @click="handleMultiCartSubmit"
          >
            <v-icon left>mdi-check</v-icon>
            Einlagern bestätigen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
}

.pos-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
}

.printer-dialog {
    display: flex;
    flex-direction: column;
    max-height: 80vh;
}

.printer-header {
    position: sticky;
    top: 0;
    z-index: 10;
    border-bottom: 1px solid rgba(0,0,0,0.12);
}

.printer-content {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
}

.printer-actions {
    position: sticky;
    bottom: 0;
    z-index: 10;
    background-color: white;
    border-top: 1px solid rgba(0,0,0,0.12);
}

/* Reset Vuetify's default list item background styles */
.printer-item {
    background-color: transparent !important;
    border-radius: 4px;
    margin: 4px 0;
    transition: all 0.2s ease;
}

/* Only apply background on hover, not for all items initially */
.printer-item:hover:not(.selected-printer) {
    background-color: #f5f5f5 !important;
}

/* Clear and specific styling for selected printer */
.selected-printer {
    background-color: #e3f2fd !important;
}
</style>