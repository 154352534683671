<template>
  <div>
    <Header />
    <v-alert :value="error" type="error">{{ error }}</v-alert>
    <v-container>
      <v-row>
        <v-col cols="12"
          ><h1 style="text-align: center">Fehlproduktionen</h1></v-col
        >
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <v-card class="mb-6">
                <v-card-title class="d-flex align-center">
                  <v-icon left color="primary" class="mr-2">mdi-magnify</v-icon>
                  Produkte suchen
                </v-card-title>
                <v-card-text>
                  <v-row align="center">
                    <v-col cols="12" md="8">
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Produktsuche (Artikelnummer, Name, etc.)"
                        placeholder="Mindestens 3 Zeichen eingeben"
                        outlined
                        dense
                        @keyup.enter="doSearch"
                        hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-btn 
                        color="primary" 
                        block 
                        @click="doSearch" 
                        :disabled="!search || search.length < 3"
                        :loading="loading">
                        <v-icon left>mdi-database-search</v-icon>
                        Suchen
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="items.length > 0">
            <v-col cols="12">
              <v-card>
                <v-card-title class="d-flex align-center">
                  <v-icon left color="success" class="mr-2">mdi-format-list-checkbox</v-icon>
                  Suchergebnisse
                  <v-chip class="ml-2" color="primary" small>{{ items.length }} Produkte</v-chip>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="items"
                  :items-per-page="10"
                  class="elevation-1"
                >
              <!-- template image -->
                <template v-slot:item.image="{ item }">
                  <v-avatar size="80" rounded>
                    <v-img 
                      :src="item.image" 
                      :alt="item.name"
                      v-if="item.image"
                      contain
                    ></v-img>
                    <v-icon
                      size="40"
                      color="grey lighten-1"
                      v-else
                    >mdi-image-off</v-icon>
                  </v-avatar>
                </template>
                
                <!-- template sku -->
                <template v-slot:item.sku="{ item }">
                  <div class="font-weight-medium">
                    {{ item.sku != null ? Array.isArray(item.sku) ? item.sku.join(", ") : item.sku : "" }}
                  </div>
                  <div class="text-caption grey--text d-none d-sm-block">
                    ID: {{ item.variationId }}
                  </div>
                </template>
                
                <!-- template name -->
                <template v-slot:item.name="{ item }">
                  <div class="font-weight-medium">{{ item.name }}</div>
                </template>

                <!-- template stock -->
                <template v-slot:item.stock="{ item }">
                  <v-chip
                    :color="getStockColor(item)"
                    text-color="white"
                    small
                  >
                    {{ item.stock != null ? item.stock.phy : 0 }}
                  </v-chip>
                </template>

                <!-- template button -->
                <template v-slot:item.action="{ item }">
                  <v-btn
                    color="error"
                    @click="selectedItem = item; selectedItemDialog = true"
                    :disabled="!item.stock || item.stock.phy <= 0"
                    outlined
                    class="mr-2"
                  >
                    <v-icon left>mdi-alert-circle</v-icon>
                    Fehlproduktion
                  </v-btn>
                </template>
              </v-data-table>
              </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-card height="100%" min-height="500px" class="mb-6">
            <v-card-title class="d-flex align-center">
              <v-icon left color="error" class="mr-2">mdi-alert-circle</v-icon>
              Angelegte Fehlproduktionen
              <v-spacer></v-spacer>
              <v-text-field
                v-model="historySearch"
                append-icon="mdi-magnify"
                label="Fehlproduktionen durchsuchen"
                single-line
                hide-details
                dense
                outlined
                class="ml-2"
                style="max-width: 300px"
              ></v-text-field>
            </v-card-title>

            <v-data-table
              :headers="historyTableHeader"
              :items="history"
              :items-per-page="10"
              :search="historySearch"
              class="elevation-1"
              :footer-props="{
                'items-per-page-options': [5, 10, 25, 50],
                'show-current-page': true,
                'show-first-last-page': true
              }"
            >
              <template v-slot:item.date="{ item }">
                {{ item.date | moment("DD.MM.yyyy HH:mm") }}
              </template>
              <template v-slot:item.packer="{ item }">
                <v-chip small color="primary" text-color="white" v-if="item.packer">
                  <v-icon left small>mdi-account</v-icon>
                  {{ item.packer }}
                </v-chip>
                <span v-else>-</span>
              </template>
              <template v-slot:item.reason="{ item }">
                <v-chip small color="red" text-color="white">{{ item.reason }}</v-chip>
              </template>
              <template v-slot:item.count="{ item }">
                <v-badge :content="item.count || 1" color="error" :value="item.count && item.count > 0">
                  <v-icon>mdi-package-variant</v-icon>
                </v-badge>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog max-width="300" v-model="loading" v-if="loading" persistent>
      <v-card>
        <v-card-title class="headline">Einen Moment bitte</v-card-title>
        <v-card-text>
          <center>
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </center>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog max-width="600" v-if="selectedItem" v-model="selectedItemDialog" persistent>
      <v-card v-if="selectedItem">
        <v-card-title class="headline primary white--text">
          <v-icon left color="white">mdi-alert-circle</v-icon>
          Fehlproduktion buchen
        </v-card-title>

        <v-card-text class="pa-4">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-chip color="primary" class="mb-2">SKU: {{ selectedItem.sku }}</v-chip>
              <h2 class="text-h5">{{ selectedItem.name }}</h2>
              <div class="d-flex align-center mt-2">
                <v-icon color="green">mdi-package-variant</v-icon>
                <span class="ml-2">Aktueller Bestand: {{ selectedItem.stock ? selectedItem.stock.phy : 0 }}</span>
              </div>
              <v-divider class="my-4"></v-divider>
            </v-col>
            
            <v-col cols="12" sm="8">
              <v-select
                v-model="damagedItem.reason"
                outlined
                label="Grund der Fehlproduktion"
                :items="reasons"
                required
                prepend-icon="mdi-information"
                :rules="[v => !!v || 'Grund ist erforderlich']"
              ></v-select>
            </v-col>
            
            <v-col cols="12" sm="4">
              <v-text-field
                outlined
                type="number"
                label="Anzahl"
                v-model="damagedItem.qty"
                min="1"
                :max="selectedItem.stock ? selectedItem.stock.phy : 10"
                :rules="[
                  v => !!v || 'Anzahl ist erforderlich',
                  v => v > 0 || 'Anzahl muss größer als 0 sein',
                  v => v <= (selectedItem.stock ? selectedItem.stock.phy : 10) || 'Anzahl kann nicht größer als der Bestand sein'
                ]"
                prepend-icon="mdi-numeric"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" v-if="damagedItem.reason == 'Eigener Grund'">
              <v-text-field
                outlined
                v-model="damagedItem.ownReason"
                label="Eigener Grund eingeben"
                prepend-icon="mdi-pencil"
                :rules="[v => !!v || 'Eigener Grund ist erforderlich']"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pa-4">
          <v-btn color="grey darken-1" text @click="selectedItemDialog = false">
            <v-icon left>mdi-close</v-icon>
            Abbrechen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            color="error" 
            @click="createDamagedItem()"
            :disabled="!isFormValid"
            :loading="submitting"
          >
            <v-icon left>mdi-content-save</v-icon>
            Fehlproduktion anlegen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="successSnackbar"
      color="success"
      timeout="3000"
      bottom
      right
    >
      <v-icon left>mdi-check-circle</v-icon>
      Fehlproduktion wurde erfolgreich angelegt!
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="successSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Header from "./header";

import service from "../../services/ProductionService.js";

export default {
  name: "damaged",
  components: {
    Header,
  },
  data: () => ({
    loading: true,
    submitting: false,
    items: [],
    search: null,
    successSnackbar: false,
    damagedItem: {
      sku: "",
      reason: "",
      qty: 1,
      ownReason: ""
    },
    selectedItem: null,
    selectedItemDialog: false,
    history: [],
    historyTableHeader: [
      { text: "Datum", value: "date", width: "150px" },
      { text: "Artikel-Nr", value: "sku", width: "150px" },
      { text: "Grund", value: "reason" },
      { text: "Anzahl", value: "count", width: "100px", align: "center" },
      { text: "Nutzer", value: "packer", width: "150px" }
    ],
    historySearch: "",

    headers: [
      { text: "Bild", value: "image", width: "120px" },
      { text: "SKU", value: "sku", width: "150px" },
      { text: "Name", value: "name" },
      { text: "Bestand", value: "stock", width: "100px", align: "center" },
      { text: "Aktion", value: "action", width: "200px", sortable: false },
    ],
      

    reasons: [
      "Eigener Grund",
      "Schräggravur",
      "Importierungsfehler",
      "Doppelt graviert",
      "Schablone nicht genau",
      "Nicht gravierte Zeichen",
      "falsches Produkt verwendet",
      "Stornierung nach Gravur",
      "Gravieren undeutlich",
      "Produkt beschädigt",
      "Muster",
      "Achse nicht eingestellt / hat sich verfahren",
      "Cobot spannt nicht richtig",
      "Zwei Gravuren auf einem Produkt",
      "Fehler bei manueller Gravur",
      "Rechts- / Linksgravur falsch",
      "Ersatzteil wurde benötigt"
    ],

    error: null,
  }),
  create() {},
  async mounted() {
    this.refreshHistory();
  },

  computed: {
    isFormValid() {
      // Basic validation
      if (!this.damagedItem.reason) return false;
      if (this.damagedItem.reason === 'Eigener Grund' && !this.damagedItem.ownReason) return false;
      if (!this.damagedItem.qty || this.damagedItem.qty <= 0) return false;
      if (this.selectedItem && this.selectedItem.stock && this.damagedItem.qty > this.selectedItem.stock.phy) return false;
      
      return true;
    }
  },

  methods: {
    async refreshHistory() {
      this.loading = true;
      try {
        const response = await service.getDamagedHistory2();
        this.history = response.data.reverse();
      } catch (error) {
        console.error("Error loading history:", error);
        this.error = "Fehler beim Laden der Fehlproduktionen";
      } finally {
        this.loading = false;
      }
    },

    async doSearch() {
      if (!this.search || this.search.length < 3) {
        this.error = "Bitte mindestens 3 Zeichen eingeben";
        return;
      }
      
      this.loading = true;
      this.error = null;
      
      try {
        const response = await service.searchWarehouseStock(this.search);
        this.items = response.data;
        
        if (this.items.length === 0) {
          this.error = "Keine Produkte gefunden";
        }
      } catch (error) {
        console.error("Error searching products:", error);
        this.error = "Fehler bei der Suche";
      } finally {
        this.loading = false;
      }
    },

    getStockColor(item) {
      if (!item.stock || item.stock.phy === undefined) return 'grey';
      
      const stock = item.stock.phy;
      if (stock <= 0) return 'red';
      if (stock < 5) return 'orange';
      if (stock < 10) return 'amber';
      return 'green';
    },
    
    async createDamagedItem() {
      if (!this.isFormValid) return;
      
      this.submitting = true;
      this.error = null;
      
      let finalReason = this.damagedItem.reason;
      if (finalReason === "Eigener Grund") {
        finalReason = "EG: "+ this.damagedItem.ownReason;
      }
      
      try {
        const payload = {
          ...this.selectedItem,
          qty: parseInt(this.damagedItem.qty, 10),
          reason: finalReason,
          sku: this.selectedItem.sku
        };

        await service.createDamagedItem2(payload);
        
        // Show success message
        this.successSnackbar = true;
        
        // Refresh history to show new entry
        await this.refreshHistory();

        // Reset form
        this.damagedItem = { 
          sku: "",
          reason: "",
          qty: 1,
          ownReason: ""
        };
        this.selectedItem = {};
        this.selectedItemDialog = false;
      } catch(error) {
        console.error("Error creating damaged item:", error);
        this.error = error.response ? error.response.data : "Fehler beim Anlegen der Fehlproduktion";
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style>
</style>