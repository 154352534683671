import Api from '@/services/Api'

export default {
  getPackagings() {
    return Api().get("/packagings");
  },
  createPackaging(data) {
    return Api().post("/packagings", data);
  },
  deletePackaging(id) {
    return Api().delete(`/packagings/${id}`);
  },
  updatePackaging(id, data) {
    return Api().put(`/packagings/${id}`, data);
  },
  updatePackagingStock(sku, newStock) {
    return Api().put(`/packagings/stock/${sku}`, { newStock: newStock });
  },
  getPackagingLogs(sku) {
    return Api().get(`/packagings/logs/${sku}`);
  },
  getUniqueSkus() {
    return Api().get('/packagings/skus');
  },
  getPackagingSelection(skus) {
    return Api().get('/packagings/selection/', {
      params: { skus: skus }
    });
  }
}