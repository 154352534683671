<template>
    <div class="d-flex overflow-x-auto packlist-items" ref="itemsContainer">
      <div 
        v-for="(item, index) in openItems" 
        :key="index" 
        class="list-item flex-shrink-0"
        :class="{
          'current-item': item.orderId === currentOrderId,
        }"
        @click="handleItemClick(item)"
        :ref="item.orderId === currentOrderId ? 'currentItem' : undefined"
      >
        <span>{{ index + 1 }}</span>
        <img :src="primeLogo" class="prime-logo" alt="Prime Logo" v-if="item.isPrime">
      </div>  
      <div 
        v-for="(item, index) in pausedItems" 
        :key="index" 
        class="list-item flex-shrink-0 warning"
        :class="{
          'current-item': item.orderId === currentOrderId
        }"
        @click="handleItemClick(item)"
        :ref="item.orderId === currentOrderId ? 'currentItem' : undefined"
      >
        <span>{{ index + 1 }}</span>
      </div>
      <div 
        v-for="(item, index) in packedItems" 
        :key="index" 
        class="list-item flex-shrink-0 success"
        :class="{
          'current-item': item.orderId === currentOrderId
        }"
        @click="handleItemClick(item)"
        :ref="item.orderId === currentOrderId ? 'currentItem' : undefined"
      >
        <v-icon>mdi-check-circle</v-icon>
      </div>  
    </div>
  </template>
  
  <script>
  import primeLogo from '@/assets/prime.png';
  
  export default {
    name: 'PacklistItems',
    props: {
      openItems: {
        type: Array,
        default: () => []
      },
      pausedItems: {
        type: Array,
        default: () => []
      },
      packedItems: {
        type: Array,
        default: () => []
      },
      currentOrderId: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        primeLogo
      };
    },
    methods: {
      handleItemClick(item) {
        this.$emit('item-click', item);
      },
      scrollToCurrentItem() {
        this.$nextTick(() => {
          if (this.$refs.currentItem && this.$refs.currentItem[0]) {
            setTimeout(() => {
              this.$refs.currentItem[0].scrollIntoView({ 
                behavior: 'smooth', 
                block: 'nearest', 
                inline: 'center'
              });
            }, 100);
          }
        });
      }
    },
    watch: {
      currentOrderId: {
        handler() {
          this.scrollToCurrentItem();
        },
        immediate: true
      },
      openItems: {
        handler() {
          if (this.currentOrderId) {
            this.scrollToCurrentItem();
          }
        },
        deep: true
      }
    },
    mounted() {
      this.scrollToCurrentItem();
    }
  }
  </script>
  
  <style scoped>
  .packlist-items {
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .packlist-items::-webkit-scrollbar {
    display: none;
  }
  
  .list-item {
    position: relative;
    width: 80px;
    height: 40px;
    min-width: 80px;
    flex-shrink: 0;
    aspect-ratio: 1;
    box-shadow: inset -4px 0 5px -2px rgba(0, 0, 0, 0.2);
  }
  
  .prime-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
  }
  
  /* Simple blue background for the current item */
  .current-item {
    background-color: #2196F3 !important; /* Blue background with !important to override other colors */
    color: white !important; /* White text for better contrast */
  }
  
  /* Make sure this has highest specificity */
  .success.current-item {
    background-color: #2196F3 !important;
    color: white !important;
  }
  
  /* And ensure the check icon also changes color */
  .success.current-item .v-icon {
    color: white !important;
  }
  </style>