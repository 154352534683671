var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"itemsContainer",staticClass:"d-flex overflow-x-auto packlist-items"},[_vm._l((_vm.openItems),function(item,index){return _c('div',{key:index,ref:item.orderId === _vm.currentOrderId ? 'currentItem' : undefined,refInFor:true,staticClass:"list-item flex-shrink-0",class:{
      'current-item': item.orderId === _vm.currentOrderId,
    },on:{"click":function($event){return _vm.handleItemClick(item)}}},[_c('span',[_vm._v(_vm._s(index + 1))]),(item.isPrime)?_c('img',{staticClass:"prime-logo",attrs:{"src":_vm.primeLogo,"alt":"Prime Logo"}}):_vm._e()])}),_vm._l((_vm.pausedItems),function(item,index){return _c('div',{key:index,ref:item.orderId === _vm.currentOrderId ? 'currentItem' : undefined,refInFor:true,staticClass:"list-item flex-shrink-0 warning",class:{
      'current-item': item.orderId === _vm.currentOrderId
    },on:{"click":function($event){return _vm.handleItemClick(item)}}},[_c('span',[_vm._v(_vm._s(index + 1))])])}),_vm._l((_vm.packedItems),function(item,index){return _c('div',{key:index,ref:item.orderId === _vm.currentOrderId ? 'currentItem' : undefined,refInFor:true,staticClass:"list-item flex-shrink-0 success",class:{
      'current-item': item.orderId === _vm.currentOrderId
    },on:{"click":function($event){return _vm.handleItemClick(item)}}},[_c('v-icon',[_vm._v("mdi-check-circle")])],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }