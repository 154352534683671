<template>
  <div>
    <Header />

    <v-container fluid>
      <!-- Combined Stats Grid -->
      <v-card class="mb-4">
        <v-card-title class="grey lighten-3 py-2">
          <v-icon left>mdi-chart-box</v-icon>
          Bestellungsübersicht
        </v-card-title>
        
        <v-row class="mx-0 pa-3" align="center">
          <!-- Shop Stats -->
          <v-col cols="12" md="6" class="d-flex align-center">
            <div class="d-flex flex-wrap align-center">
              <div class="shop-stat mr-4 mb-3 d-flex align-center">
                <img src="@/assets/amazon.png" height="20px" class="mr-2" />
                <span class="font-weight-medium text-body-1">{{ openOrders.filter((x) => x.shop == "amazon").length }}</span>
              </div>
              
              <div class="shop-stat mr-4 mb-3 d-flex align-center">
                <img src="@/assets/shopify.png" height="20px" class="mr-2" />
                <span class="font-weight-medium text-body-1">{{ openOrders.filter((x) => x.shop == "shopify").length }}</span>
              </div>
              
              <div class="shop-stat mr-4 mb-3 d-flex align-center">
                <img src="@/assets/etsy.png" height="20px" class="mr-2" />
                <span class="font-weight-medium text-body-1">{{ openOrders.filter((x) => x.shop == "etsy").length }}</span>
              </div>
              
              <div class="shop-stat mb-3 d-flex align-center">
                <img src="@/assets/acs.png" height="20px" class="mr-2" />
                <span class="font-weight-medium text-body-1">{{ openOrders.filter((x) => x.shop == "acs").length }}</span>
              </div>
            </div>
          </v-col>
          
          <!-- Total Stats -->
          <v-col cols="12" md="6" class="d-flex flex-column justify-center">
            <div class="position-relative h-100">
              <v-divider vertical class="hidden-sm-and-down mr-4 divider-vertical"></v-divider>
              <v-divider class="hidden-md-and-up mb-3"></v-divider>
            </div>
            
            <div class="d-flex flex-wrap align-center">
              <div class="stat-badge mb-2 mr-2 px-3 py-1 rounded primary white--text d-inline-flex align-center">
                <v-icon left small class="mr-1">mdi-package</v-icon>
                <span>Offen: {{ openOrders.length }}</span>
              </div>
              
              <div class="stat-badge mb-2 mr-2 px-3 py-1 rounded deep-purple white--text d-inline-flex align-center">
                <v-icon left small class="mr-1">mdi-star</v-icon>
                <span>Prime: {{ openOrders.filter((x) => x.shipping.type == "PRIME").length }}</span>
              </div>
              
              <div class="stat-badge mb-2 mr-2 px-3 py-1 rounded red white--text d-inline-flex align-center">
                <v-icon left small class="mr-1">mdi-calendar-today</v-icon>
                <span>Heute: {{ packagesToDay.length }}</span>
              </div>
              
              <div class="stat-badge mb-2 mr-2 px-3 py-1 rounded blue white--text d-inline-flex align-center">
                <v-icon left small class="mr-1">mdi-flash</v-icon>
                <span>Heute (Prime): {{ packagesToDay.filter((x) => x.shipping.type == "PRIME").length }}</span>
              </div>
              
              <div v-if="packagesToDay.filter((x) => x.shipping.type == 'EXPRESS').length > 0" 
                class="stat-badge mb-2 px-3 py-1 rounded orange white--text d-inline-flex align-center">
                <v-icon left small class="mr-1">mdi-lightning-bolt</v-icon>
                <span>EXPRESS: {{ packagesToDay.filter((x) => x.shipping.type == "EXPRESS").length }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <!-- Loading Indicator -->
      <div v-if="loading" class="text-center py-5">
        <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
        <div class="mt-3">Lade Bestellungsdaten...</div>
      </div>

      <!-- Tab Layout for Orders -->
      <v-card v-else>
        <v-tabs
          v-model="activeTab"
          background-color="primary"
          dark
          show-arrows
          slider-color="yellow"
          class="badge-tabs"
        >
          <v-tab key="today">
            <v-icon left>mdi-calendar-today</v-icon>
            Heute fällig ({{ packagesToDay.length }})
          </v-tab>
          
          <v-tab key="prime">
            <v-icon left>mdi-star</v-icon>
            Prime ({{ primeOrders.length }})
          </v-tab>
          
          <v-tab key="regular">
            <v-icon left>mdi-package-variant</v-icon>
            Normal ({{ nonPrimeOrders.length }})
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="activeTab">
          <!-- Today's Orders Tab -->
          <v-tab-item key="today">
            <v-card flat>
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-card elevation="0" class="pa-3 rounded-0">
                    <v-card-title class="headline red--text">
                      <v-icon large color="red" class="mr-2">mdi-alarm</v-icon>
                      Heute fällige Bestellungen
                    </v-card-title>
                    <v-card-text>
                      <OrderList
                        v-if="packagesToDay.length > 0"
                        :packlist="packlist"
                        :orders="packagesToDay"
                      ></OrderList>
                      <v-alert v-else type="info" text>
                        Keine Bestellungen für heute fällig.
                      </v-alert>
                    </v-card-text>
                  </v-card>
                </v-col>
                
                <v-col cols="12" md="6">
                  <v-card elevation="0" class="pa-3 rounded-0">
                    <v-card-title class="headline blue--text">
                      <v-icon large color="blue" class="mr-2">mdi-alarm-check</v-icon>
                      Prime Bestellungen für heute
                    </v-card-title>
                    <v-card-text>
                      <OrderList
                        v-if="primeTodayOrders.length > 0"
                        :packlist="packlist"
                        :orders="primeTodayOrders"
                      ></OrderList>
                      <v-alert v-else type="info" text>
                        Keine Prime-Bestellungen für heute fällig.
                      </v-alert>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
          
          <!-- Prime Orders Tab -->
          <v-tab-item key="prime">
            <v-card flat>
              <v-card-title class="headline blue--text">
                <v-icon large color="blue" class="mr-2">mdi-star</v-icon>
                Alle Prime Bestellungen
              </v-card-title>
              <v-card-text>
                <OrderList
                  v-if="primeOrders.length > 0"
                  :packlist="packlist"
                  :orders="primeOrders"
                ></OrderList>
                <v-alert v-else type="info" text>
                  Keine Prime-Bestellungen gefunden.
                </v-alert>
              </v-card-text>
            </v-card>
          </v-tab-item>
          
          <!-- Regular Orders Tab -->
          <v-tab-item key="regular">
            <v-card flat>
              <v-card-title class="headline grey--text text--darken-2">
                <v-icon large color="grey darken-2" class="mr-2">mdi-package-variant</v-icon>
                Alle regulären Bestellungen
              </v-card-title>
              <v-card-text>
                <OrderList
                  v-if="nonPrimeOrders.length > 0"
                  :packlist="packlist"
                  :orders="nonPrimeOrders"
                ></OrderList>
                <v-alert v-else type="info" text>
                  Keine regulären Bestellungen gefunden.
                </v-alert>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      
      <!-- Refresh Button -->
      <v-btn
        color="primary"
        fab
        fixed
        bottom
        right
        class="mb-4 mr-4"
        @click="manualRefresh"
        :loading="loading"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import Header from "./header";
import OrderList from "./OrderList";
import dashboardService from "@/services/DashboardService";
import router from "@/router";
export default {
  name: "dashboard",
  components: {
    Header,
    OrderList,
  },
  data: () => ({
    timer: null,
    loading: true,
    openOrders: [],
    packlist: [],
    shippedOrders: [],
    activeTab: 0, // Default to today's orders tab
  }),
  
  async mounted() {
    // Initial loading with spinner
    this.refresh(true);
    
    // Auto refresh every 15 seconds without spinner
    this.timer = setInterval(() => this.refresh(false), 1000 * 15);
  },
  
  methods: {
    async refresh(showLoading = false) {
      if (showLoading) {
        this.loading = true;
      }
      
      try {
        let dashboardData = ((await dashboardService.get()).data);
        this.packlist = dashboardData.packlist;
        let data = dashboardData.openOrders.filter(
          (x) => x.shipping != null
        );
        this.openOrders = data.sort((a, b) =>
          new Date(a.createDate).getTime() > new Date(b.createDate).getTime()
            ? -1
            : 1
        );
      } catch (err) {
        console.error(err);
        if (err.response.status == 401) {
          router.replace("/auth/login");
        }
      } finally {
        this.loading = false;
      }
    },
    
    // Manual refresh button handler
    manualRefresh() {
      this.refresh(true);
    },
  },
  computed: {
    packagesToDay: function () {
      if (!this.openOrders.length) return [];
      
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      
      return this.openOrders.filter(o => {
        const date = o.shipping.lastShippmentDate || o.shipping.shippmentDate;
        const shipDate = new Date(date);
        shipDate.setHours(0, 0, 0, 0);
        return shipDate.getTime() <= now.getTime();
      });
    },
    
    primeOrders() {
      return this.openOrders.filter(x => x.shipping.type === 'PRIME');
    },
    
    nonPrimeOrders() {
      return this.openOrders.filter(x => x.shipping.type !== 'PRIME');
    },
    
    primeTodayOrders() {
      return this.packagesToDay.filter(x => x.shipping.type === 'PRIME');
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style>
/* Mobile optimizations */
@media (max-width: 600px) {
  .v-tab {
    min-width: auto !important;
    padding: 0 8px !important;
  }

  .headline {
    font-size: 1.1rem !important;
  }

  /* Entfernt */

  .v-card-title {
    padding: 8px 12px !important;
  }
  
  .shop-stat {
    padding: 4px 8px !important;
    min-width: 70px !important;
  }
}

/* General improvements */
.v-tab {
  letter-spacing: 0;
}

.text-h3 {
  line-height: 1.1 !important;
}

/* Simple tab styling */
.v-tab {
  position: relative;
  margin-right: 4px;
  text-transform: none !important;
  font-weight: 500;
}

/* Make tab content fit on smaller screens */
@media (max-width: 400px) {
  .v-tab {
    font-size: 0.8rem !important;
  }
}

/* Shop stats styling */
.shop-stat {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 6px 12px;
  min-width: 80px;
  background-color: #f9f9f9;
}

.stat-badge {
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  font-weight: 500;
  font-size: 14px;
}

.divider-vertical {
  position: absolute;
  height: 100%;
  top: 0;
  left: -12px;
}
</style>
