<script>
export default {
    name: 'PacklistActions',
    props: {
        currentPacklistItem: {
            type: Object,
            required: true
        },
        customer: {
            type: Object,
            required: true
        },
        blackList: {
            type: Array,
            required: true
        },
        isPrinted: {
            type: Boolean,
            required: true
        },
        isShippingReady: {
            type: Boolean,
            required: true
        },
        isMulticart: {
            type: Boolean,
            required: true
        },
        showScrollIndicator: {
            type: Boolean,
            required: true
        },
        isPrinting: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            under8cm: true
        }
    },
    methods: {
        handleProblem() {
            this.$emit('build-problem-modal');
        },
        handleMulticart() {
            this.$emit('build-multicart-modal');
        },
        handlePrintRequest() {
            this.$emit('print-request', true, this.under8cm);
        },
        openMulticartWarning() {
            this.$emit('open-multicart-warning');
        },
        handlePackRequest() {
            this.$emit('handle-pack-request');
        },
        scrollToNextItem() {
            this.$emit('scroll-to-next-item');
        }
    }
}
</script>

<template>
    <div class="pa-2 position-relative">
        <div class="d-flex justify-center align-center gap-2">
            <span class="text-subtitle-1 font-weight-bold green--text">{{ customer.firstName }} {{ customer.lastName }}</span><span class="text-caption">{{ customer.adress }}</span>
        </div>
        
        <v-card v-if="currentPacklistItem && !currentPacklistItem.registered" class="size-selection mb-3 mt-2 elevation-0">
          <v-card-subtitle class="pa-2 text-center warning--text font-weight-medium">
            <v-icon small color="warning" class="mr-1">mdi-ruler</v-icon>
            Paketgröße wählen
          </v-card-subtitle>
          <v-card-text class="pa-2">
            <v-row>
              <v-col cols="6" class="py-0">
                <v-btn @click="under8cm = true" small block 
                  :color="under8cm ? 'primary' : ''" 
                  :outlined="!under8cm"
                  class="font-weight-medium">
                  <v-icon small left>mdi-package-variant-closed</v-icon>
                  Unter 8 cm
                </v-btn>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-btn @click="under8cm = false" small block 
                  :color="!under8cm ? 'primary' : ''" 
                  :outlined="under8cm"
                  class="font-weight-medium">
                  <v-icon small left>mdi-package-variant</v-icon>
                  Über 8 cm
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        
        <v-row class="d-flex justify-center align-center gap-2">
            <v-btn color="error" class="w-50" @click="handleProblem">
                <v-icon>mdi-alert</v-icon>
                Problem
            </v-btn>
            <v-btn color="warning" class="w-50" @click="openMulticartWarning">
                <v-icon>mdi-alert</v-icon>
                Warenkorbregal
            </v-btn>
            <v-btn 
              v-if="isShippingReady && !isPrinted" 
              color="primary" 
              class="w-50 print-button" 
              @click="handlePrintRequest"
              :loading="isPrinting"
              style="margin-top: 20px;"
              :disabled="isPrinting"
            >
              <template v-slot:loader>
                <v-progress-circular
                  indeterminate
                  color="white"
                  size="24"
                ></v-progress-circular>
              </template>
              <v-icon v-if="!isPrinting">mdi-printer</v-icon>
              <span class="ml-1">{{ isPrinting ? 'Druckt...' : 'Drucken' }}</span>
            </v-btn>
            <v-btn v-else-if="isShippingReady" style="margin-top: 20px;" color="success" class="w-50" @click="handlePackRequest">
                <v-icon>mdi-check</v-icon>
                Gepackt
            </v-btn>
        </v-row>

        <div v-if="showScrollIndicator" @click="scrollToNextItem">
            <div class="scroll-indicator">
                <v-icon>mdi-arrow-down</v-icon>
            </div>
        </div>
    </div>
</template>

<style scoped>
.w-50 {
    width: 48%;
}

.print-button {
    height: 80px;
    font-size: 1.4em;
    font-weight: bold;
}

.gap-2 {
    gap: 1rem;
}

.btnSelected {
    background-color: #2196F3 !important;
    color: white !important;
}

.mb-2 {
    margin-bottom: 0.5rem;
}

.scroll-indicator {
  position: absolute;
  top: -84px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(33, 150, 243, 0.9);
  color: white;
  padding: 24px;
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: pulse 1.5s infinite ease-in-out;
}

.scroll-indicator .v-icon {
  display: block;
  margin: 0 auto;
  animation: bounce 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(33, 150, 243, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(33, 150, 243, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(33, 150, 243, 0);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
</style>