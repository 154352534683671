<template>
  <div class="dashboard-container">
    <v-container fluid class="pa-4">
      <!-- Systemübersicht mit Karten im Grid-Layout -->
      <v-row>
        <v-col cols="12">
          <h1 class="dashboard-title mb-4">Admin Dashboard</h1>
        </v-col>
      </v-row>
      
      <!-- System Status Sektion -->
      <v-row v-if="system">
        <v-col cols="12" lg="8">
          <v-card class="system-card" elevation="4">
            <v-card-title class="d-flex align-center">
              <div>
                <h2 class="section-title">Systeminformationen</h2>
                <div class="subtitle">{{ system.host }}</div>
              </div>
              <v-spacer></v-spacer>
              <v-chip color="primary" small class="refresh-chip" @click="refresh">
                <v-icon left small>mdi-refresh</v-icon>
                Aktualisieren
              </v-chip>
            </v-card-title>
            
            <v-card-text>
              <v-row>
                <!-- Systemmetriken -->
                <v-col cols="12" md="4" v-for="(metric, index) in systemMetrics" :key="index">
                  <v-card class="metric-card" :color="metric.color" flat>
                    <div class="d-flex align-center">
                      <div class="metric-gauge">
                        <v-progress-circular
                          :rotate="270"
                          :size="90"
                          :width="12"
                          :value="metric.value"
                          :color="metric.gaugeColor"
                          class="metric-progress"
                        >
                          <span class="metric-value">{{ Math.round(metric.value) }}%</span>
                        </v-progress-circular>
                      </div>
                      <div class="metric-info ml-4">
                        <h3 class="metric-title">{{ metric.title }}</h3>
                        <div class="metric-details">{{ metric.details }}</div>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
              
              <v-row class="mt-4">
                <!-- CPU Temperatur -->
                <v-col cols="12" md="6">
                  <v-card class="temperature-card" elevation="0">
                    <div class="d-flex align-center">
                      <v-icon size="40" :color="getCpuTempColor" class="mr-4">mdi-thermometer</v-icon>
                      <div>
                        <h3 class="metric-title">CPU-Temperatur</h3>
                        <div class="temperature-container">
                          <div class="temperature-bar" :style="{ width: getTempPercentage + '%', backgroundColor: getTempGradientColor }"></div>
                          <div class="temperature-value text-h4 font-weight-bold">{{ system.cpuTemp }}°C</div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-col>
                
                <!-- Server Load -->
                <v-col cols="12" md="6">
                  <v-card class="load-card" elevation="0">
                    <h3 class="metric-title mb-2">Server Load (12 Kerne)</h3>
                    
                    <!-- Server Load Chart -->
                    <div class="server-load-wrapper mb-2">
                      <v-sparkline
                        :value="system.load"
                        smooth
                        :gradient="['#4CAF50', '#FFA726', '#FF5252']"
                        gradient-direction="top"
                        fill
                        height="80"
                        padding="6"
                        :line-width="2"
                      ></v-sparkline>
                      
                      <!-- Reference lines -->
                      <div class="reference-container">
                        <div class="reference-line" style="top: 20%;">
                          <span class="reference-label">12 Kerne</span>
                        </div>
                        <div class="reference-line" style="top: 50%;">
                          <span class="reference-label-left">6 Kerne</span>
                        </div>
                      </div>
                    </div>
                    
                    <!-- Load statistics -->
                    <div class="d-flex justify-space-between load-labels mb-2">
                      <div class="load-label">1 min: <b>{{ system.load[0].toFixed(2) }}</b></div>
                      <div class="load-label">5 min: <b>{{ system.load[1].toFixed(2) }}</b></div>
                      <div class="load-label">15 min: <b>{{ system.load[2].toFixed(2) }}</b></div>
                    </div>
                    
                    <!-- Load status indicator -->
                    <div class="load-status-bar">
                      <div class="load-status-progress" :style="{ width: getCurrentLoadPercent + '%', backgroundColor: getLoadStatusColor }"></div>
                    </div>
                    <div class="d-flex justify-space-between mt-1">
                      <v-chip small :color="getLoadChipColor" dark class="my-1">
                        {{ getLoadStatus }}
                      </v-chip>
                      <span class="load-percent align-self-center">{{ getCurrentLoadPercent }}% Auslastung</span>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          
          <!-- Prozessliste -->
          <v-card v-if="processes && processes.length" class="mt-4 process-card" elevation="4">
            <v-card-title>
              <h2 class="section-title">Laufende Prozesse</h2>
              <v-spacer></v-spacer>
              <v-chip color="secondary" small>{{ processList.length }} Prozesse</v-chip>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="processHeaders"
                :items="processList"
                :items-per-page="10"
                class="process-table"
                dark
                dense
                sort-by="memory"
                :sort-asc="true"
              >
                <template v-slot:item.status="{ item }">
                  <v-chip
                    small
                    :color="item.status === 'online' ? 'success' : 'error'"
                    class="status-chip"
                  >
                    {{ item.status }}
                  </v-chip>
                </template>
                <template v-slot:item.memory="{ item }">
                  <div>
                    <v-progress-linear
                      :value="Math.min(item.memory / 10, 100)"
                      :color="getMemoryColor(item.memory)"
                      height="8"
                      rounded
                    ></v-progress-linear>
                    <small>{{ item.memory.toFixed(0) }} MB</small>
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn icon x-small text>
                    <v-icon small>mdi-restart</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        
        <!-- Rechte Spalte mit Bestellungen und Druckern -->
        <v-col cols="12" lg="4">
          <!-- Bestellungsstatistiken -->
          <v-card v-if="orders" class="orders-card" elevation="4">
            <v-card-title>
              <h2 class="section-title">Bestellungen</h2>
            </v-card-title>
            <v-card-text>
              <v-row class="order-stats">
                <v-col cols="6" v-for="(stat, index) in orderStats" :key="index">
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      :elevation="hover ? 8 : 0"
                      :class="['order-stat-card', hover ? 'hovered' : '']"
                      :color="stat.color"
                    >
                      <v-card-text class="pa-3">
                        <div class="order-stat-value" :class="{ 'animate-value': animateValues.openOrdersTotal && stat.label === 'Offene Bestellungen' || 
                                                             animateValues.unpackedOrders && stat.label === 'Nicht verpackt' || 
                                                             animateValues.finishedOrders && stat.label === 'Abgeschlossen' }">{{ stat.value }}</div>
                        <div class="order-stat-label">{{ stat.label }}</div>
                        <v-icon class="order-stat-icon">{{ stat.icon }}</v-icon>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
              
              <!-- Bestellungsbereiche -->
              <div class="mt-4">
                <h3 class="metric-title">Bestellungen nach Marktplatz</h3>
                <v-list dense dark class="marketplace-list pa-0" rounded>
                  <v-list-item
                    v-for="(value, key) in orders.openOrders"
                    :key="key"
                    class="marketplace-item"
                  >
                    <v-list-item-avatar>
                      <v-avatar size="32" :color="getMarketplaceColor(key)">
                        <span class="white--text text-uppercase">{{ key.charAt(0) }}</span>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ key }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip color="primary" class="marketplace-count">{{ value }}</v-chip>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </div>
              
              <!-- Bestellungsstatistik als Balkendiagramm -->
              <div class="mt-4 order-bar-chart-container" :class="{ 'animate-chart': animateValues.openOrdersTotal || animateValues.unpackedOrders || animateValues.finishedOrders }">
                <h3 class="metric-title mb-3">Bestellungsstatistik</h3>
                <apexchart
                  type="bar"
                  height="200"
                  :options="barChartOptions"
                  :series="barChartSeries"
                ></apexchart>
              </div>
              
              <!-- Bestellungschart -->
              <div class="mt-4">
                <h3 class="metric-title mb-2">Bestellungsübersicht</h3>
                <apexchart
                  type="donut"
                  height="250"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </v-card-text>
          </v-card>
          
          <!-- Drucker Status -->
          <v-card class="mt-4 printers-card" elevation="4">
            <v-card-title>
              <h2 class="section-title">Drucker</h2>
              <v-spacer></v-spacer>
              <v-chip color="info" small>
                {{ onlinePrinters }}/{{ printers.length }} Online
              </v-chip>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-list dense dark class="printer-list">
                <v-list-item
                  v-for="printer in printers"
                  :key="printer._id"
                  :class="['printer-item', printer.online ? 'printer-online' : 'printer-offline']"
                >
                  <v-list-item-avatar>
                    <v-icon :color="printer.online ? 'success' : 'error'">
                      {{ printer.online ? 'mdi-printer' : 'mdi-printer-off' }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ printer.name.replace("Etikettendrucker_", "Nr. ") }}</v-list-item-title>
                    <v-list-item-subtitle>{{ printer.updated | formatDate }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon x-small text>
                      <v-icon small>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
          
          <!-- MongoDB Status -->
          <v-card v-if="mongoDB" class="mt-4 mongo-card" elevation="4">
            <v-card-title>
              <h2 class="section-title">
                <v-icon left>mdi-database</v-icon>
                MongoDB
              </h2>
            </v-card-title>
            <v-card-text>
              <v-row class="mongo-stats">
                <v-col cols="4" class="mongo-stat">
                  <div class="mongo-stat-value">{{ mongoDB.version }}</div>
                  <div class="mongo-stat-label">Version</div>
                </v-col>
                <v-col cols="4" class="mongo-stat">
                  <div class="mongo-stat-value">{{ Math.round(mongoDB.uptime / 60 / 60 / 24) }}</div>
                  <div class="mongo-stat-label">Tage Uptime</div>
                </v-col>
                <v-col cols="4" class="mongo-stat">
                  <div class="mongo-stat-value">{{ mongoDB.connections ? mongoDB.connections.current : 0 }}</div>
                  <div class="mongo-stat-label">Verbindungen</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Header from "./header";
import dashboardService from "@/services/DashboardService";
import moment from 'moment';

export default {
  name: "dashboard",
  components: {
    Header,
  },
  
  filters: {
    formatDate(date) {
      if (!date) return '';
      return moment(date).format('DD.MM HH:mm');
    }
  },
  
  data: () => ({
    timer: null,
    loading: true,
    
    // Vorherige Werte für Animationsvergleich
    previousValues: {
      openOrdersTotal: 0,
      unpackedOrders: 0,
      finishedOrders: 0
    },
    
    // Animation Flags
    animateValues: {
      openOrdersTotal: false,
      unpackedOrders: false,
      finishedOrders: false
    },
    
    system: {
      host: "server.accelery.host",
      cpuUsage: 0,
      memUsage: 0,
      uptime: 0,
      cpuTemp: 0,
      diskUsage: 0,
      load: [0, 0, 0],
      processes: 0,
    },
    processes: [],
    orders: {
      openOrdersTotal: 0,
      openOrders: { amazon: 0, prime: 0, shopify: 0, etsy: 0, acs: 0 },
      openOrdersToday: 0,
      openOrdersPrime: 0,
      unpackedOrders: 0,
      finishedOrders: 0,
    },
    printers: [],
    mongoDB: {
      version: 0,
    },
    
    processHeaders: [
      { text: 'Name', value: 'name' },
      { text: 'Status', value: 'status' },
      { text: 'Neustarts', value: 'restarts' },
      { text: 'RAM (MB)', value: 'memory', sortable: true, sort: (a, b) => b - a },
      { text: 'Aktion', value: 'actions', sortable: false }
    ],
    
    // Chart Optionen
    chartOptions: {
      chart: {
        type: 'donut',
        fontFamily: 'Roboto, sans-serif',
        foreColor: '#E0E0E0',
      },
      labels: ['Offen', 'Nicht verpackt', 'Abgeschlossen'],
      colors: ['#2196F3', '#FF9800', '#4CAF50'],
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        offsetY: 0,
        fontWeight: 500,
        labels: {
          colors: '#E0E0E0',
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: '60%',
            labels: {
              show: true,
              name: {
                show: true,
                fontWeight: 'bold',
              },
              value: {
                show: true,
                fontWeight: 'bold',
                fontSize: '22px',
              },
              total: {
                show: true,
                label: 'Gesamt',
                fontWeight: 'bold',
                fontSize: '16px',
              }
            }
          }
        }
      },
      stroke: {
        width: 2,
        colors: ['#1E1E1E']
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        enabled: true,
        theme: 'dark'
      }
    },
    
    // Balkendiagramm-Optionen
    barChartOptions: {
      chart: {
        type: 'bar',
        fontFamily: 'Roboto, sans-serif',
        foreColor: '#E0E0E0',
        toolbar: {
          show: false
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '60%',
          borderRadius: 6,
          distributed: true,
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
          colors: ['#fff']
        },
        formatter: function(val) {
          return val.toString();
        }
      },
      grid: {
        show: true,
        borderColor: 'rgba(255, 255, 255, 0.1)',
        strokeDashArray: 5,
        position: 'back'
      },
      colors: ['#2196F3', '#FF9800', '#4CAF50'],
      xaxis: {
        categories: ['Offen', 'Nicht verpackt', 'Abgeschlossen'],
        labels: {
          style: {
            colors: '#BDBDBD'
          }
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: '#BDBDBD'
          },
          formatter: function(val) {
            return Math.round(val);
          }
        }
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val + " Bestellungen";
          }
        },
        theme: 'dark'
      },
      legend: {
        show: false
      }
    }
  }),
  
  mounted() {
    this.refresh();
    this.timer = setInterval(this.refresh, 10000); // Alle 10 Sekunden aktualisieren
    document.querySelector('.v-main').style.setProperty('background', '#121212');
  },
  
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  
  computed: {
    // Systemmetriken berechnen
    systemMetrics() {
      if (!this.system) return [];
      
      return [
        {
          title: 'CPU-Auslastung',
          value: this.system.cpuUsage,
          details: `${Math.round(this.system.cpuUsage)}% verwendet`,
          color: 'rgba(33, 150, 243, 0.1)',
          gaugeColor: '#2196F3'
        },
        {
          title: 'RAM-Nutzung',
          value: this.system.memUsage,
          details: `${Math.round(this.system.memUsage)}% verwendet`,
          color: 'rgba(156, 39, 176, 0.1)',
          gaugeColor: '#9C27B0'
        },
        {
          title: 'Festplattennutzung',
          value: this.system.diskUsage,
          details: `${this.system.diskUsage}% belegt`,
          color: 'rgba(255, 152, 0, 0.1)',
          gaugeColor: '#FF9800'
        }
      ];
    },
    
    // Bestellungsstatistiken
    orderStats() {
      if (!this.orders) return [];
      
      return [
        {
          label: 'Offene Bestellungen',
          value: this.orders.openOrdersTotal,
          color: 'rgba(33, 150, 243, 0.1)',
          icon: 'mdi-cart-outline'
        },
        {
          label: 'Heute offen',
          value: this.orders.openOrdersToday,
          color: 'rgba(156, 39, 176, 0.1)',
          icon: 'mdi-calendar-today'
        },
        {
          label: 'Nicht verpackt',
          value: this.orders.unpackedOrders,
          color: 'rgba(255, 152, 0, 0.1)',
          icon: 'mdi-package-variant-closed'
        },
        {
          label: 'Abgeschlossen',
          value: this.orders.finishedOrders,
          color: 'rgba(76, 175, 80, 0.1)',
          icon: 'mdi-check-circle-outline'
        }
      ];
    },
    
    // Berechnete Series für das Chart
    series() {
      if (!this.orders) return [0, 0, 0];
      return [
        this.orders.openOrdersTotal - this.orders.unpackedOrders,
        this.orders.unpackedOrders,
        this.orders.finishedOrders
      ];
    },
    
    // Berechnete Series für das Balkendiagramm
    barChartSeries() {
      if (!this.orders) return [{data: [0, 0, 0]}];
      return [{
        name: 'Bestellungen',
        data: [
          this.orders.openOrdersTotal - this.orders.unpackedOrders,
          this.orders.unpackedOrders,
          this.orders.finishedOrders
        ]
      }];
    },
    
    // CPU Temperatur Farbe
    getCpuTempColor() {
      if (!this.system) return 'success';
      
      const temp = this.system.cpuTemp;
      if (temp >= 80) return 'error';
      if (temp >= 60) return 'warning';
      return 'success';
    },
    
    // CPU Temperatur Prozent (für visuelle Darstellung)
    getTempPercentage() {
      if (!this.system) return 0;
      // Wir nehmen 100°C als Maximum an
      return Math.min(Math.max(this.system.cpuTemp, 0), 100);
    },
    
    // CPU Temperatur Farbverlauf
    getTempGradientColor() {
      if (!this.system) return '#4CAF50';
      
      const temp = this.system.cpuTemp;
      if (temp >= 80) return '#FF5252';
      if (temp >= 70) return '#FFA726';
      if (temp >= 60) return '#FFC107';
      if (temp >= 50) return '#8BC34A';
      return '#4CAF50';
    },
    
    // Prozesse ohne PM2
    processList() {
      if (!this.processes) return [];
      return this.processes.filter(x => x.name.indexOf('pm2') === -1);
    },
    
    // Anzahl der online-Drucker
    onlinePrinters() {
      return this.printers.filter(p => p.online).length;
    },
    
    // Server Load Status
    getLoadStatus() {
      if (!this.system) return 'Normal';
      
      const loadAvg = this.system.load[0]; // 1 Minute Load
      
      if (loadAvg >= 12) return 'Überlastet';
      if (loadAvg >= 9) return 'Hoch';
      if (loadAvg >= 6) return 'Mittel';
      return 'Normal';
    },
    
    // Server Load Chip Color
    getLoadChipColor() {
      if (!this.system) return 'success';
      
      const loadAvg = this.system.load[0]; // 1 Minute Load
      
      if (loadAvg >= 12) return 'error';
      if (loadAvg >= 9) return 'warning';
      if (loadAvg >= 6) return 'info';
      return 'success';
    },
    
    // Server Load Percentage
    getCurrentLoadPercent() {
      if (!this.system) return 0;
      
      // Load im Verhältnis zu 12 Kernen
      const loadPercent = (this.system.load[0] / 12) * 100;
      return Math.min(Math.round(loadPercent), 100);
    },
    
    // Server Load Status Color
    getLoadStatusColor() {
      if (!this.system) return '#4CAF50';
      
      const loadAvg = this.system.load[0]; // 1 Minute Load
      
      if (loadAvg >= 12) return '#FF5252';
      if (loadAvg >= 9) return '#FFA726';
      if (loadAvg >= 6) return '#FFC107';
      return '#4CAF50';
    }
  },
  
  methods: {
    async refresh() {
      this.loading = true;
      try {
        const data = (await dashboardService.getAdminDashbaord()).data;
        
        // Speichere die vorherigen Werte für Animationsvergleich
        if (this.orders) {
          this.previousValues = {
            openOrdersTotal: this.orders.openOrdersTotal,
            unpackedOrders: this.orders.unpackedOrders,
            finishedOrders: this.orders.finishedOrders
          };
        }
        
        this.system = data.system;
        this.orders = data.orders;
        this.processes = data.processes;
        this.mongoDB = data.mongo;
        this.printers = data.printers;
        
        // Prüfe auf positive Veränderungen und setze Animationsflags
        if (this.orders && this.previousValues.openOrdersTotal > 0) {
          this.animateValues = {
            openOrdersTotal: this.orders.openOrdersTotal > this.previousValues.openOrdersTotal,
            unpackedOrders: this.orders.unpackedOrders > this.previousValues.unpackedOrders,
            finishedOrders: this.orders.finishedOrders > this.previousValues.finishedOrders
          };
          
          // Reset Animation-Flags nach kurzer Zeit
          setTimeout(() => {
            this.animateValues = {
              openOrdersTotal: false,
              unpackedOrders: false,
              finishedOrders: false
            };
          }, 2000);
        }
      } catch (err) {
        console.error('Fehler beim Laden der Dashboard-Daten:', err);
      } finally {
        this.loading = false;
      }
    },
    
    // Hilfsmethoden
    getMemoryColor(memory) {
      if (memory > 500) return 'error';
      if (memory > 200) return 'warning';
      return 'success';
    },
    
    getMarketplaceColor(marketplace) {
      const colors = {
        amazon: '#FF9900',
        prime: '#00A8E1',
        shopify: '#95BF47',
        etsy: '#F45800',
        acs: '#673AB7',
      };
      
      return colors[marketplace.toLowerCase()] || 'primary';
    }
  }
};
</script>

<style scoped>
.dashboard-container {
  min-height: 100vh;
  background-color: #121212;
}

.dashboard-title {
  color: #fff;
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 0.5px;
  margin-bottom: 1.5rem;
}

.section-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #BBDEFB;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.subtitle {
  color: #90CAF9;
  font-size: 0.9rem;
  margin-top: -5px;
}

.system-card, .process-card, .orders-card, .printers-card, .mongo-card {
  background-color: #1A1A1A;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
}

.metric-card {
  padding: 1rem;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.metric-card:hover {
  transform: translateY(-5px);
}

.metric-gauge {
  position: relative;
}

.metric-progress {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}

.metric-value {
  position: absolute;
  font-size: 1rem;
  font-weight: 600;
  color: white;
}

.metric-title {
  font-size: 1rem;
  font-weight: 500;
  color: #E0E0E0;
  margin-bottom: 0.25rem;
}

.metric-details {
  font-size: 0.85rem;
  color: #BDBDBD;
}

.temperature-card, .load-card {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 1rem;
}

.temperature-container {
  position: relative;
  height: 48px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  overflow: hidden;
  margin-top: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.temperature-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  transition: width 0.5s ease, background-color 0.5s ease;
  z-index: 1;
  border-radius: 24px;
}

.temperature-value {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  z-index: 2;
  font-weight: bold;
}

.server-load-wrapper {
  position: relative;
  width: 100%;
  height: 80px;
  border-radius: 8px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.2);
}

.reference-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.reference-line {
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  border-top: 1px dashed rgba(255, 255, 255, 0.4);
}

.reference-label {
  position: absolute;
  top: -8px;
  right: 8px;
  font-size: 10px;
  background-color: rgba(255, 82, 82, 0.7);
  color: white;
  padding: 0 4px;
  border-radius: 2px;
}

.reference-label-left {
  position: absolute;
  top: -8px;
  left: 8px;
  font-size: 10px;
  background-color: rgba(255, 193, 7, 0.7);
  color: white;
  padding: 0 4px;
  border-radius: 2px;
}

.load-labels {
  font-size: 0.85rem;
  color: #BDBDBD;
}

.load-status-bar {
  width: 100%;
  height: 6px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  overflow: hidden;
}

.load-status-progress {
  height: 100%;
  transition: width 0.5s ease, background-color 0.5s ease;
}

.load-percent {
  font-size: 0.85rem;
  color: #BDBDBD;
}

.refresh-chip {
  cursor: pointer;
  transition: transform 0.2s ease;
}

.refresh-chip:hover {
  transform: scale(1.05);
}

.status-chip {
  font-weight: 600;
}

.process-table {
  background-color: #1A1A1A !important;
}

.order-stats {
  margin: 0 -6px;
}

.order-stat-card {
  position: relative;
  padding: 0.75rem;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
  margin-bottom: 8px;
}

.order-stat-card.hovered {
  transform: translateY(-5px);
}

.order-stat-value {
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
}

.order-stat-label {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
}

.order-stat-icon {
  position: absolute;
  bottom: 8px;
  right: 8px;
  font-size: 2rem;
  opacity: 0.2;
}

.marketplace-list {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.marketplace-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.marketplace-count {
  font-weight: 600;
}

.printer-list {
  max-height: 300px;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.2) !important;
  border-radius: 8px;
}

.printer-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  transition: background-color 0.2s ease;
}

.printer-item:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.mongo-stats {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin: 0 -8px;
}

.mongo-stat {
  text-align: center;
  padding: 1rem;
}

.mongo-stat-value {
  font-size: 1.5rem;
  font-weight: 600;
  color: #BBDEFB;
}

.mongo-stat-label {
  font-size: 0.85rem;
  color: #BDBDBD;
}

.error {
  color: #FF5252;
}

.warning {
  color: #FFC107;
}

.success {
  color: #4CAF50;
}

/* Animationen */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.hovered {
  animation: pulse 1.5s infinite;
}

/* Vue 2 Kompatibilitätsanpassungen */
.d-flex {
  display: flex !important;
}

.align-center {
  align-items: center !important;
}

.justify-space-between {
  justify-content: space-between !important;
}

.order-bar-chart-container {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);
}

/* Bar Chart Animations */
@keyframes barGrow {
  0% { transform: scaleY(0); }
  100% { transform: scaleY(1); }
}

.apexcharts-bar-area {
  animation: barGrow 1s ease-out forwards;
  transform-origin: bottom;
}

/* Animationen für positive Veränderungen */
@keyframes positive-pulse {
  0% { transform: scale(1); box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.4); }
  50% { transform: scale(1.05); box-shadow: 0 0 0 10px rgba(76, 175, 80, 0); }
  100% { transform: scale(1); box-shadow: 0 0 0 0 rgba(76, 175, 80, 0); }
}

@keyframes chart-glow {
  0% { box-shadow: 0 0 5px rgba(76, 175, 80, 0.4); }
  50% { box-shadow: 0 0 20px rgba(76, 175, 80, 0.8); }
  100% { box-shadow: 0 0 5px rgba(76, 175, 80, 0.4); }
}

.animate-value {
  animation: positive-pulse 1s ease-in-out;
  color: #4CAF50 !important;
  text-shadow: 0 0 10px rgba(76, 175, 80, 0.7);
}

.animate-chart {
  animation: chart-glow 2s ease-in-out;
}

.animate-chart .apexcharts-bar-area {
  animation: barGrow 1.5s ease-out forwards, positive-pulse 1s ease-in-out;
}
</style>