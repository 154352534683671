import Api from '@/services/Api'
import axios from 'axios'

export default {
  getV2() {
    return Api().get('v2/production')
  },
  getV3() {
    return Api().get('v3/production')
  },

  getV2Analytics(date) {
    return Api().post('v2/analytics', {date: date})
  },

  getItem(name) {
    return Api().get(`production/${name}`);
  },
 
  createShipping(name) {
    return Api().post(`createShipping/${name}`);
  },
  generateBabyCube(page) {
    return Api().get(`/plugin/babycube/generate?page=${page}`);
  },
  generateDateKeychain(page) {
    return Api().get(`/plugin/datekeychain/generate?page=${page}`);
  },
  generatePhotoZippo() {
    return Api().get(`photozippo/download/now`, { timeout: 0 });
  },
  generatePhotoKeychain() {
    return Api().get(`photokeychain/download/now`, { timeout: 0 });
  },
  generatePhotoAMZKeychain() {
    return Api().get(`photoAMZkeychain/download/now`, { timeout: 0 });
  },
  generatePhotoAMZZippo() {
    return Api().get(`photoAMZzippo/download/now`, { timeout: 0 });
  },
  createDamagedItem(payload) {
    return Api().post(`damaged/product`, payload);
  },
  getDamagedHistory() {
    return Api().get("damaged/products");
  },
  applyTrash(orderId, variationId, qty) {
    return Api().post(`damaged/trash`, { orderId: orderId, variationId: variationId, qty: qty });
  },

  //v2 damaged routes
  getDamagedHistory2() {
    return Api().get("damaged/v2/history");
  },

  searchWarehouseStock(name) {
    ///damaged/v2/search
    return Api().get(`damaged/v2/search?q=${name}`);
  },

  //create damaged item
  createDamagedItem2(payload) {
    return Api().post(`damaged/v2/product`, payload);
  },

  createPacklist(name) {
    return Api().post(`packlist/${name}`);
  },

  getRetoureHistory() {
    return Api().get("retoure");
  },
  createRetoure(payload) {
    return Api().post(`retoure`, payload);
  },
  getRetoureShelf() {
    return Api().get("retoure/shelf");
  },
  updateRetoure(payload, billbee) {
    return Api().post(`retoure/update/${payload.retoure.id}?billbee=${billbee}`, payload);
  },
  loadOrderData(orderId) {
    return Api().post(`retoure/loadOrderData`, { orderId: orderId });
  },

  reShipRetoure(orderId, payload) {
    return Api().post(`retoure/ship/${encodeURIComponent(orderId)}`, payload);
  },

  markAsShipped(orderId, table) {
    return Api().post(`reproduction/markAsShipped`, { orderId: orderId, table: table });
  },

  searchOrder(searchTerm) {
    return Api().get("search?q=" + encodeURIComponent(searchTerm))
  },

  searchCustom(searchTerm) {
    return Api().post("searchCustom", { query: searchTerm })
  },

  getOrder(orderId) {
    return Api().get(`order/${encodeURIComponent(orderId)}`);
  },

  getOrderByInternalId(managedId) {
    return Api().get(`order/internal/${encodeURIComponent(managedId)}`);
  },

  getMongoOrder(orderId) {
    return Api().get(`mongo/order/${encodeURIComponent(orderId)}`);
  },

  getTrackingState(trackingId) {
    return Api().post("trackingState", { trackingId: trackingId });
  },

  getReusableItems(sku) {
    return Api().get(`reusableItems/${sku}`)
  },

  reuseItem(id) {
    return Api().post(`reusableItems/${id}`)
  },

  updateEntry(table, item) {
    return Api().post(`update/${table}`, item)
  },

  deleteEntry(table, id) {
    return Api().delete(`/delete/${table}/${id}`)
  },

  getWarehouse() {
    return Api().get(`warehouse`)
  },

  getProductionTime(date = null) {
    return Api().get(`/controlling/productiontime/${date}`)
  },

  getProductionTimeLine(date = null) {
    return Api().get(`/controlling/productiontimeline/${date}`)
  },

  getCartons() {
    return Api().get(`cartons`)
  },

  connectCarton(cartonId, productId) {
    return Api().post(`connectCarton`, { cartonId: cartonId, productId: productId })
  },

  

  createBarcode(barcode, format) {
    return Api().get(`/plugin/barcode/${barcode}/${format}`)
  },

  createPackageList(from, to) {
    return Api().post(`/packageList`, { from: from, to: to})
  },

  uspGetArtworks() {
    return Api().get(`/plugin/usg/artworks`)
  },
  uspGenerateOrderId(orderId) {
    return Api().get(`/plugin/usg/generate/${encodeURIComponent(orderId)}`)
  },
  uspGetOrderForSku(sku) {
    return Api().get(`/plugin/usg/order/${encodeURIComponent(sku)}`)
  },

  uspUpdateArtwork(artwork) {
    return Api().post(`/plugin/usg/artworks/update`, artwork);
  },

  uspUploadArtwork(file) {
    return Api().post(`/plugin/usg/artworks/upload`, {file: file});
  },

  productionAddOrder(orderId) {
    return Api().post(`/production/add`, {orderId: orderId});
  },

  releaseState(table, id) {
    return Api().post("/production/release", { table: table, id: id});
  },

  createRekla(data) {
    return Api().post("/rekla", data);
  },

  getReklaInformations(orderId) {
    return Api().get(`/rekla/validation/${orderId}`);
  },

  getPrinters() {
    return axios.get('/api/v2/packlist/printers/active');
  },

  printShippingLabel(data) {
    return axios.post('/api/shipping/label/print', data);
  }

}