<script>
export default {
    name: 'PacklistOrder',
    props: {
        order: {
            type: Object,
            required: true
        },
        acsCustomer: {
            type: Object,
            required: true
        },
        currentScrollIndex: {
            type: Number,
            required: true
        },
        currentProducts: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            isDarkBackground: false,
            markedProducts: [],
            darkmodePacklistPrefixes: [
                'printcut',
                'nachtlicht',
                'florithings',
                'uv_print'
            ]
        };
    },
    methods: {
        getImageUrl(product) {
            let hasDesignId = product.designId || product.attributes.designId;
            let hasPreview = product.attributes.preview;

            if (hasPreview) {
                return hasPreview[0].url;
            } else if (hasDesignId) {
                return `https://accelery-poster.s3.eu-central-1.amazonaws.com/${encodeURIComponent(hasDesignId)}.png`;
            } else {
                let foundImageUrl = null;
                for (const [key, value] of Object.entries(product.attributes)) {
                    if (typeof value === 'string' && value.startsWith('http')) {
                        foundImageUrl = value;
                        break;
                    }
                }

                return foundImageUrl;
            }
        },
        toggleBackgroundMode() {
            this.isDarkBackground = !this.isDarkBackground;

            let backgroundSettings = {
                isDarkBackground: this.isDarkBackground
            }

            localStorage.setItem('backgroundSettings', JSON.stringify(backgroundSettings));
        },
        toggleProductAsMarked(productId) {
            if (this.markedProducts.includes(productId)) {
                // If already marked, remove from marked products
                this.markedProducts.splice(this.markedProducts.indexOf(productId), 1);
            } else {
                // If not marked, add to marked products
                this.markedProducts.push(productId);
            }
        },
        openDamagedDialog() {
            this.$emit('open-damaged-dialog');
        },
        getStatusColor(status) {
            if (this.currentProducts.length > 1) return 'purple darken-2';
            if (status === 'ready') return 'success';
            return 'error';
        },
        getStatusMessage(product) {
            if (product.status === 'ready' && product.shelf) {
                return `Bereit in Fach ${product.shelf.slot}`;
            } else if (product.status === 'ready') {
                return 'Bereit';
            } else {
                return product.status.charAt(0).toUpperCase() + product.status.slice(1);
            }
        },
        getFields(product) {
            if (!product.translated) return [];

            let blacklist = ['_', 'preview', 'designId', 'vQty', 'qty', 'grossPrice', 'discount'];

            return Object.entries(product.attributes)
                .filter(([key, value]) => {
                    // Check if value is not an HTTP URL and not null
                    const isValidValue = !value?.startsWith?.('http') && value !== null;
                    
                    // Check if key doesn't contain any blacklisted substrings
                    const isKeyAllowed = !blacklist.some(blockedStr => 
                        key.toLowerCase().includes(blockedStr.toLowerCase())
                    );
                    
                    return isValidValue && isKeyAllowed;
                })
                .map(([key, value]) => ({
                    key,
                    value
                }));
        },
        getBackgroundSettings() {
            let backgroundSettings = localStorage.getItem('backgroundSettings');

            if (!backgroundSettings) {
                let newBackgroundSettings = {
                    isDarkBackground: false
                }

                localStorage.setItem('backgroundSettings', JSON.stringify(newBackgroundSettings));
            } else {
                this.isDarkBackground = JSON.parse(backgroundSettings).isDarkBackground;
            }
        }
    },
    mounted() {
        let packlistName = this.$route.params.id;

        for (const prefix of this.darkmodePacklistPrefixes) {
            if (packlistName.includes(prefix)) {
                this.isDarkBackground = true;
                break;
            }
        }

        this.getBackgroundSettings();
    },
    watch: {
        currentScrollIndex: {
            handler(newVal) {
                this.$nextTick(() => {
                    if (this.$refs.scrollContainer && 
                        Array.isArray(this.$refs.scrollContainer) && 
                        newVal >= 0 && newVal < this.$refs.scrollContainer.length) {
                        // Access the actual DOM element using $el
                        const element = this.$refs.scrollContainer[newVal].$el;
                        if (element && typeof element.scrollIntoView === 'function') {
                            element.scrollIntoView({ 
                                behavior: 'smooth',
                                block: 'start'
                            });
                        } else {
                            console.warn('Element does not have scrollIntoView method');
                        }
                    } else {
                        console.warn(`Cannot scroll to index ${newVal}: Element not found (available: ${this.$refs.scrollContainer ? this.$refs.scrollContainer.length : 0} elements)`);
                    }
                });
            }
        }
    }
}
</script>

<template>
    <div class="pa-2" style="padding-bottom: 220px !important;">
        <!-- Products List -->
        <div class="product-cards" v-if="currentProducts.length > 0">
            <v-card
                v-for="(product, index) in currentProducts"
                :key="`${product.sku}_${index}`" 
                class="product-card mb-4"
                ref="scrollContainer"
                :class="{
                    'marked': markedProducts.includes(`${product.sku}_${index}`)
                }"
                elevation="2"
            >
                <!-- Status Bar -->
                <div class="status-bar" :class="getStatusColor(product.status)">
                    <div class="status-indicator">
                        <v-icon small class="mr-1" color="white">
                            {{ product.status === 'ready' ? 'mdi-check-circle' : 'mdi-close-circle' }}
                        </v-icon>
                        <span class="white--text font-weight-medium">{{ getStatusMessage(product) }}</span>
                    </div>
                    <div class="quantity-indicator">
                        <v-icon small color="white">mdi-tag</v-icon>
                        <span class="white--text font-weight-medium">Menge: {{ product.vQty || product.qty }}</span>
                    </div>
                </div>
                
                <!-- Main content -->
                <div class="product-content pa-4" :class="{'marked': markedProducts.includes(`${product.sku}_${index}`)}">
                    <div class="two-column-layout">
                        <!-- Product Image -->
                        <div class="product-image-container">
                            <div class="product-image" :class="{ 'dark-mode': isDarkBackground }">
                                <v-img
                                    @click="toggleBackgroundMode"
                                    :src="getImageUrl(product)"
                                    contain
                                    width="100%"
                                    height="300px"
                                    class="product-design-image"
                                ></v-img>
                                <v-btn
                                    icon
                                    x-small
                                    class="mode-toggle"
                                    @click="toggleBackgroundMode"
                                    :color="isDarkBackground ? 'white' : 'black'"
                                >
                                    <v-icon>{{ isDarkBackground ? 'mdi-white-balance-sunny' : 'mdi-moon-waning-crescent' }}</v-icon>
                                </v-btn>
                            </div>
                        </div>
                        
                        <!-- Product Info -->
                        <div class="product-info" :class="{'ml-4': product.designId}">
                            <!-- SKU -->
                            <div class="sku-container d-flex align-center mb-3">
                                <div class="text-h6 font-weight-bold">{{ product.sku }}</div>
                                <v-chip
                                    v-if="product.qty > 1"
                                    color="error"
                                    small
                                    class="ml-2"
                                >
                                    Menge: {{ product.qty }}
                                </v-chip>
                            </div>
                            
                            <!-- Custom Fields -->
                            <div class="custom-fields mb-3">
                                <div v-for="(field, i) in getFields(product)" :key="i" class="custom-field">
                                    <span class="field-name">{{ field.key }}:</span>
                                    <span class="field-value">{{ field.value }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Actions Bar -->
                    <div class="actions-bar d-flex justify-space-between align-center mt-4">
                        <v-btn 
                            text
                            small
                            color="error"
                            @click="openDamagedDialog"
                        >
                            <v-icon small left>mdi-refresh-circle</v-icon>
                            Zurücksetzen
                        </v-btn>
                        
                        <v-btn 
                            rounded
                            :color="markedProducts.includes(`${product.sku}_${index}`) ? 'success' : 'grey lighten-1'"
                            class="mark-button"
                            @click="toggleProductAsMarked(`${product.sku}_${index}`)"
                        >
                            <v-icon left>
                                {{ markedProducts.includes(`${product.sku}_${index}`) ? 'mdi-check' : 'mdi-hand-back-left' }}
                            </v-icon>
                            {{ markedProducts.includes(`${product.sku}_${index}`) ? 'Gefunden' : 'Als gefunden markieren' }}
                        </v-btn>
                    </div>
                </div>
            </v-card>
        </div>
        
        <!-- Empty State -->
        <div v-else class="empty-product-list">
            <v-alert type="info" class="no-products-alert">
                Keine Produkte zum Verpacken vorhanden.
            </v-alert>
        </div>
    </div>
</template>

<style scoped>
.product-cards {
    display: flex;
    flex-direction: column;
}

.product-card {
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s ease;
    border: 2px solid transparent;
}

.product-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}

.product-card.marked {
    background-color: #4CAF50;
}

.product-card.marked::after {
    content: '✓';
    position: absolute;
    top: 58px;
    right: 16px;
    width: 30px;
    height: 30px;
    background-color: #4CAF50;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    z-index: 2;
}

.status-bar {
    padding: 10px 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.status-bar.success {
    background-color: #4CAF50;
}

.status-bar.error {
    background-color: #F44336;
}

.status-indicator, .quantity-indicator {
    display: flex;
    align-items: center;
}

.product-content {
    background-color: white;
}

.two-column-layout {
    display: grid;
    grid-template-columns: minmax(50%, 1fr) minmax(0, 1fr);
    gap: 20px;
    align-items: start;
}

.product-image-container {
    width: 100%;
}

.product-image {
    width: 100%;
    background: #666;
    border-radius: 6px;
    padding: 16px;
    position: relative;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.product-image.dark-mode {
    background: #191919;
}

.mode-toggle {
    position: absolute;
    bottom: 8px;
    right: 8px;
    z-index: 1;
    opacity: 0.6;
    transition: opacity 0.2s ease;
    background-color: rgba(255, 255, 255, 0.2);
}

.mode-toggle:hover {
    opacity: 1;
}

.product-info {
    width: 100%;
    min-width: 0; /* Important for text overflow to work */
    padding-top: 4px;
}

.sku-container {
    flex-wrap: wrap;
}

.custom-fields {
    padding-right: 8px;
}

.custom-field {
    margin-bottom: 8px;
    font-size: 22px;
    display: flex;
    flex-wrap: wrap;
    line-height: 1.4;
}

.field-name {
    font-weight: 600;
    min-width: 120px;
    color: #546e7a;
    flex-shrink: 0;
}

.field-value {
    flex-grow: 1;
    word-break: break-word;
}

.actions-bar {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding-top: 15px;
    margin-top: 10px;
}

.mark-button {
    transition: all 0.3s ease;
    padding: 0 24px;
}

.empty-product-list {
    padding: 20px;
}

.no-products-alert {
    text-align: center;
}

.custom-fields-container {
    background-color: #f5f7fa;
    border-left: 3px solid #3f51b5;
    border-radius: 4px;
}

.custom-field {
    display: flex;
    padding: 4px 0;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}

.custom-field:last-child {
    border-bottom: none;
}

/* For fields with long values */
@media (max-width: 600px) {
    .custom-field {
        flex-direction: column;
    }
    
    .field-name {
        margin-bottom: 2px;
    }
}
</style>