<script>
import ProductionService from '../../../services/ProductionService';

export default {
    name: 'PacklistModals',
    props: {
        dialogs: {
            type: Object,
            required: true
        },
        multiCartItems: {
            type: Array,
            default: () => []
        },
        currentItem: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        },
        currentPacklistItemUserLaser: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            tabIndex: 0,
            problemType: null,
            problemText: null,
            problemTypes: [
                'Fehlende Produkte',
                'Beschädigte Produkte',
                'Falsche Produkte',
                'Druckfehler',
                'Systemfehler',
                'Sonstiges'
            ],
            errorTypes: [
                'Eigener Grund',
                'Schräggravur',
                'Importierungsfehler',
                'Doppelt Graviert',
                'Schablone nicht genau',
                'Nicht gravierte Zeichen',
                'Falsches Produkt verwendet',
                'Stornierung nach Gravur',
                'Gravur undeutlich',
                'Muster',
                'Achse nicht eingestellt',
                'Cobot spannt nicht richtig',
                'Zwei Gravuren auf einem Produkt',
                'Fehler bei manueller Gravur',
                'Rechts / Links vertauscht',
                'Ersatzteil wurde benötigt'
            ],
            engravingOrderId: null,
            engravingErrorType: null,
            engravingErrorDetails: null,
            selectedCartShelf: null,
            markedProductsMap: {},
            loadingFullOrder: false,
            fullOrder: null,
            selectedEngravingItem: null,
            selectedEngravingItems: [],
            errorReasons: {},
        }
    },
    methods: {
        async getFullOrderInformation() {
            this.loadingFullOrder = true;

            try {
                if (!this.currentItem || !this.currentItem.orderId) {
                    console.warn('Cannot fetch order: currentItem or orderId is missing');
                    this.loadingFullOrder = false;
                    return;
                }

                let response = await ProductionService.getMongoOrder(this.currentItem.orderId);

                if (response.status === 200) {
                    this.fullOrder = response.data;
                } else {
                    console.error('Failed to load order:', response);
                }
            } catch (error) {
                console.error('Error fetching order information:', error);
            } finally {
                this.loadingFullOrder = false;
            }
        },
        reportProblem() {
            let message = this.problemType + ' - ' + this.problemText;
            this.$emit('reportProblem', message);
        },
        async bookEngravingError() {
            try {
                console.log(this.selectedEngravingItems);

                let isLoading = true;
                while (isLoading) {
                    for (const item of this.selectedEngravingItems) {
                        // Get error reason details
                        const errorReason = this.getItemErrorReason(item);
                        const reason = errorReason.type ? 
                            `${errorReason.type === 'Eigener Grund' ? 'EG' : errorReason.type}${errorReason.details ? ': ' + errorReason.details : ''}` : 
                            "Kein Grund angegeben";

                        let payload = {
                            sku: item.Product.SKU,
                            name: item.Product.Title,
                            variationId: item.BillbeeId,
                            itemId: item.Product.BillbeeId,
                            isMain: true,
                            isActive: true,
                            image: null,
                            qty: item.Quantity,
                            reason: reason,
                            packerId: this.user.id,
                            producerId: parseInt(this.currentPacklistItemUserLaser)
                        }

                        console.log(payload);
                        let response = await ProductionService.createDamagedItem2(payload);

                        if (response.status === 200) {
                            console.log('Item created successfully for item: ' + item.Product.SKU);
                        } else {
                            console.error('Failed to create item');
                        }
                    }

                    isLoading = false;
                }

                if (!isLoading) {
                    this.dialogs.problem = false;
                    this.$emit('refreshPacklist');
                }
            } catch (error) {
                console.error(error);
            }
        },
        groupProductsBySlot(products) {
            const groups = {};
            
            for (const product of products) {
                const slot = product.slot || 'Unbekannt';
                
                if (!groups[slot]) {
                    groups[slot] = [];
                }
                
                groups[slot].push(product);
            }
            
            return groups;
        },
        getShelfColor(slot) {
            // Generate consistent colors based on shelf location
            const shelfColors = {
                'A': 'blue darken-2',
                'B': 'green darken-2',
                'C': 'purple darken-2',
                'D': 'orange darken-2'
            };
            
            // Get color based on first letter of slot, default to deep-orange
            const firstLetter = slot.charAt(0).toUpperCase();
            return shelfColors[firstLetter] || 'deep-orange darken-2';
        },
        toggleProductMarked(productId) {
            // Use Vue's reactivity system to update the object
            if (this.markedProductsMap[productId]) {
                this.$set(this.markedProductsMap, productId, false);
            } else {
                this.$set(this.markedProductsMap, productId, true);
            }
        },
        isProductMarked(productId) {
            return !!this.markedProductsMap[productId];
        },
        pushBackProducts() {
            this.$emit('pushBackProducts');
        },
        pushToMulticartShelf() {
            // Close the warning dialog if it's open
            if (this.dialogs.multicartWarning) {
                this.dialogs.multicartWarning = false;
            }
            
            // Only emit the event if the shopping list isn't already open
            if (!this.dialogs.multicartShoppingList) {
                this.$emit('pushToMulticartShelf');
            }
        },
        selectEngravingProduct(item) {
            // For backward compatibility
            this.selectedEngravingItem = item;
            this.engravingOrderId = item.Product.SKU;
            
            // Toggle selection for the multi-select functionality
            this.toggleEngravingProduct(item);
        },
        toggleEngravingProduct(item, event) {
            // If clicking on a form control or its child, don't toggle selection
            if (event && 
                (event.target.closest('.v-select') || 
                 event.target.closest('.v-text-field') || 
                 event.target.closest('.error-reason-inputs'))) {
                return;
            }
            
            const index = this.selectedEngravingItems.findIndex(i => i.BillbeeId === item.BillbeeId);
            
            if (index > -1) {
                // Item is already selected, remove it
                this.selectedEngravingItems.splice(index, 1);
            } else {
                // Item is not selected, add it
                this.selectedEngravingItems.push(item);
            }
        },
        isEngravingProductSelected(item) {
            return this.selectedEngravingItems.some(i => i.BillbeeId === item.BillbeeId);
        },
        filterTextAttributes(attributes) {
            return attributes.filter(attr => 
                attr.Name && 
                attr.Value && 
                !attr.Value.startsWith('http')
            );
        },
        filterImageAttributes(attributes) {
            return attributes.filter(attr => 
                attr.Value && 
                attr.Value.startsWith('http')
            );
        },
        hasImageAttribute(attributes) {
            return attributes.some(attr => 
                attr.Value && 
                attr.Value.startsWith('http')
            );
        },
        setItemErrorReason(item, type, details) {
            // Initialize the error reason object if it doesn't exist
            if (!this.errorReasons[item.BillbeeId]) {
                this.$set(this.errorReasons, item.BillbeeId, { type: null, details: null });
            }
            
            // Update error type and details
            this.$set(this.errorReasons[item.BillbeeId], 'type', type);
            this.$set(this.errorReasons[item.BillbeeId], 'details', details);
        },
        getItemErrorReason(item) {
            return this.errorReasons[item.BillbeeId] || { type: null, details: null };
        },
        hasItemErrorReason(item) {
            return this.errorReasons[item.BillbeeId] && 
                  (this.errorReasons[item.BillbeeId].type || this.errorReasons[item.BillbeeId].details);
        }
    },
    computed: {
        canReportEngravingProblem() {
            // Check if there are selected items AND at least one item has error details
            return this.selectedEngravingItems.length > 0 && 
                  this.selectedEngravingItems.some(item => this.hasItemErrorReason(item));
        },
        canReportProblem() {
            if (this.tabIndex === 0) {
                return this.problemType && this.problemText;
            } else {
                return this.canReportEngravingProblem;
            }
        }
    },
    watch: {
        'dialogs.problem': {
            async handler(newVal) {
                if (newVal) {
                    // Reset selections when dialog opens
                    this.selectedEngravingItems = [];
                    this.selectedEngravingItem = null;
                    this.errorReasons = {}; // Reset error reasons for new session
                    await this.getFullOrderInformation();
                }
            },
            immediate: true
        }
    }
}
</script>

<template>
    <div>
        <!-- Problem Modal -->
        <v-dialog v-model="dialogs.problem" fullscreen persistent :retain-focus="false">
            <v-card class="problem-dialog-card d-flex flex-column" height="100%">
                <!-- Sticky header with tabs -->
                <div class="sticky-header-container">
                    <v-card-title class="error white--text sticky-header pa-4">
                        <v-icon class="mr-2">mdi-alert</v-icon>
                        Problem melden
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="dialogs.problem = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    
                    <v-tabs v-model="tabIndex" color="error" background-color="error" dark class="sticky-tabs">
                        <v-tab class="white--text">
                            <v-icon left>mdi-alert-circle-outline</v-icon>
                            Problem
                        </v-tab>
                        <v-tab class="white--text" :disabled="loadingFullOrder">
                            <v-icon v-if="loadingFullOrder" left>mdi-loading</v-icon>
                            <v-icon v-else left>mdi-alert</v-icon>
                            Fehlproduktion ausbuchen
                        </v-tab>
                    </v-tabs>
                </div>
                
                <!-- Scrollable content area -->
                <v-card-text class="flex-grow-1 overflow-y-auto pt-6">
                    <v-tabs-items v-model="tabIndex">
                        <v-tab-item>
                            <v-select
                                v-model="problemType"
                                :items="problemTypes"
                                label="Problemtyp"
                                class="mb-4"
                                outlined
                                hide-details="auto"
                            />
                            <v-textarea 
                                v-model="problemText" 
                                label="Beschreibung des Problems" 
                                outlined
                                auto-grow
                                rows="4"
                            />
                        </v-tab-item>
                        
                        <v-tab-item v-if="fullOrder">
                            <div v-if="loadingFullOrder" class="d-flex justify-center my-6">
                                <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
                                <div class="ml-4 text-subtitle-1">Bestelldaten werden geladen...</div>
                            </div>
                            
                            <template v-else-if="fullOrder && fullOrder.billbeeOrder && fullOrder.billbeeOrder.Data && fullOrder.billbeeOrder.Data.OrderItems && fullOrder.billbeeOrder.Data.OrderItems.length > 0">
                                <div class="d-flex justify-space-between align-center mb-4">
                                    <p class="text-h6 font-weight-medium mb-0">Produkte mit Gravierfehler:</p>
                                    <div class="selection-counter">
                                        <v-chip color="primary" outlined v-if="selectedEngravingItems.length > 0">
                                            <v-icon left small>mdi-counter</v-icon>
                                            {{ selectedEngravingItems.length }} ausgewählt
                                        </v-chip>
                                    </div>
                                </div>

                                <!-- Product List Layout -->
                                <v-list class="product-list rounded" elevation="1">
                                    <v-list-item
                                        v-for="item in fullOrder.billbeeOrder.Data.OrderItems"
                                        :key="item.BillbeeId"
                                        @click="$event => toggleEngravingProduct(item, $event)"
                                        class="product-list-item"
                                        :class="{'selected-product-list-item': isEngravingProductSelected(item)}"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title class="d-flex align-center mb-2">
                                                <span class="product-sku grey--text text--darken-1 mr-2">{{ item.Product.SKU }}</span>
                                                <span class="product-title font-weight-bold text-truncate">{{ item.Product.Title }}</span>
                                                <v-chip color="primary" small dark class="ml-2">{{ item.Quantity }}x</v-chip>
                                            </v-list-item-title>
                                            
                                            <v-list-item-subtitle class="mt-2">
                                                <div class="d-flex mt-1">
                                                    <!-- Left: Image preview if available -->
                                                    <div v-if="hasImageAttribute(item.Attributes)" class="product-image mr-4">
                                                        <v-img
                                                            :src="filterImageAttributes(item.Attributes)[0].Value"
                                                            height="120"
                                                            width="120"
                                                            contain
                                                            class="rounded grey lighten-4 elevation-1"
                                                        ></v-img>
                                                        <div v-if="filterImageAttributes(item.Attributes).length > 1" class="image-badge">
                                                            +{{ filterImageAttributes(item.Attributes).length - 1 }}
                                                        </div>
                                                    </div>
                                                    
                                                    <!-- Right: Product attributes -->
                                                    <div class="product-info flex-grow-1">
                                                        <template v-if="filterTextAttributes(item.Attributes).length > 0">
                                                            <div class="attributes-list">
                                                                <div v-for="(attr, i) in filterTextAttributes(item.Attributes)" :key="i" class="attribute-row">
                                                                    <span class="attribute-label">{{ attr.Name }}:</span>
                                                                    <span class="attribute-value">{{ attr.Value }}</span>
                                                                </div>
                                                            </div>
                                                        </template>
                                                        <div v-else class="no-attributes grey--text">
                                                            Keine Personalisierung vorhanden
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <!-- Error reason input fields - shown only when item is selected -->
                                                <div v-if="isEngravingProductSelected(item)" class="error-reason-inputs mt-4 pt-3 border-top">
                                                    <div class="d-flex align-center mb-2">
                                                        <v-icon color="error" class="mr-2">mdi-alert-circle</v-icon>
                                                        <span class="font-weight-medium">Fehlergrund angeben:</span>
                                                    </div>
                                                    <div class="d-flex gap-3">
                                                        <v-select
                                                            :value="getItemErrorReason(item).type"
                                                            @input="val => setItemErrorReason(item, val, getItemErrorReason(item).details)"
                                                            :items="errorTypes"
                                                            label="Fehlertyp"
                                                            outlined
                                                            dense
                                                            hide-details="auto"
                                                            class="flex-grow-0 flex-shrink-0"
                                                            style="width: 40%;"
                                                            color="error"
                                                            @click.stop
                                                            @mousedown.stop
                                                        ></v-select>
                                                        <v-text-field
                                                            v-if="getItemErrorReason(item).type === 'Eigener Grund'"
                                                            :value="getItemErrorReason(item).details"
                                                            @input="val => setItemErrorReason(item, getItemErrorReason(item).type, val)"
                                                            label="Beschreibung"
                                                            outlined
                                                            dense
                                                            hide-details="auto"
                                                            class="flex-grow-1"
                                                            color="error"
                                                            @click.stop
                                                            @mousedown.stop
                                                        ></v-text-field>
                                                    </div>
                                                </div>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        
                                        <v-list-item-action>
                                            <v-checkbox
                                                :input-value="isEngravingProductSelected(item)"
                                                color="success"
                                                readonly
                                                class="selection-checkbox"
                                                dense
                                                :ripple="false"
                                            ></v-checkbox>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </template>
                            
                            <v-alert type="warning" v-else>
                                Keine Produkte in dieser Bestellung gefunden.
                            </v-alert>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
                
                <v-divider></v-divider>
                
                <!-- Sticky footer with info and actions -->
                <v-card-actions class="sticky-footer px-4 py-3">
                    <v-alert type="info" class="order-info-alert ma-0 mr-auto" dense>
                        <div class="d-flex align-center">
                            <div>
                                <div class="font-weight-medium" v-if="currentItem">
                                    <v-icon left small>mdi-account</v-icon>
                                    {{ currentItem.customer ? `${currentItem.customer.firstName} ${currentItem.customer.lastName}` : 'Kein Kundenname' }}
                                </div>
                                <div class="font-weight-medium" v-else>
                                    <v-icon left small>mdi-account</v-icon>
                                    Kein Kundenname
                                </div>
                                <div class="mt-1" v-if="currentItem">
                                    <v-icon left small>mdi-package-variant</v-icon>
                                    Bestellnummer: <span class="font-weight-bold">{{ currentItem.orderId || 'N/A' }}</span>
                                </div>
                                <div class="mt-1" v-else>
                                    <v-icon left small>mdi-package-variant</v-icon>
                                    Bestellnummer: <span class="font-weight-bold">N/A</span>
                                </div>
                            </div>
                        </div>
                    </v-alert>
                    
                    <v-btn @click="dialogs.problem = false" text class="ml-4">Schließen</v-btn>
                    <v-btn 
                        v-if="tabIndex === 0"
                        @click="reportProblem" 
                        color="error" 
                        :disabled="!canReportProblem"
                        class="ml-2"
                    >
                        <v-icon left>mdi-alert</v-icon>
                        Problem melden
                    </v-btn>
                    <v-btn 
                        v-if="tabIndex === 1"
                        @click="bookEngravingError" 
                        color="error" 
                        :disabled="!canReportProblem"
                        class="ml-2"
                    >
                        <v-icon left>mdi-alert</v-icon>
                        Ausbuchen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Multicart Warning Modal -->
        <v-dialog v-model="dialogs.multicartWarning" max-width="550" :retain-focus="false">
            <v-card>
                <v-card-title class="error white--text">
                    <v-icon left color="white">mdi-package-variant-remove</v-icon>
                    Produkte nicht versandbereit
                </v-card-title>
                
                <v-card-text class="pt-4">
                    <p class="text-body-1 mb-3">
                        Die folgenden Produkte in dieser Bestellung sind noch nicht für den Versand bereit:
                    </p>
                    
                    <v-list dense class="not-ready-products grey lighten-4 rounded mb-4">
                        <v-list-item v-for="product in currentItem.products.filter(product => product.status !== 'ready')" :key="product.sku">
                            <v-list-item-icon>
                                <v-icon color="error">mdi-package-variant-closed-remove</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium">{{ product.sku }}</v-list-item-title>
                                <v-list-item-subtitle>Status: {{ product.status || 'Nicht bereit' }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-chip color="error" small>{{ product.qty }}x</v-chip>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                    
                    <v-alert type="info" outlined dense>
                        Was möchten Sie mit dieser Bestellung tun?
                    </v-alert>
                </v-card-text>
                
                <v-divider></v-divider>
                
                <v-card-actions class="action-buttons pa-4">
                    <v-btn 
                        color="grey darken-1"
                        text
                        @click="pushBackProducts"
                        class="action-button"
                    >
                        <v-icon left>mdi-arrow-right-bold</v-icon>
                        Nach hinten schieben
                    </v-btn>
                    
                    <v-spacer></v-spacer>
                    
                    <v-btn 
                        color="warning"
                        @click="pushToMulticartShelf"
                        class="action-button"
                    >
                        <v-icon left>mdi-cart</v-icon>
                        Ins Warenkorbregal
                    </v-btn>
                    
                    <v-btn 
                        color="info"
                        @click="dialogs.multicartWarning = false"
                        class="action-button ml-2"
                    >
                        <v-icon left>mdi-eye</v-icon>
                        Bestellung ansehen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogs.multicartButReadyWarning" max-width="500" persistent :retain-focus="false">
            <v-card>    
                <v-card-title class="warning white--text headline">
                    <v-icon left color="white">mdi-cart-variant</v-icon>
                    Multiwarenkorb-Bestellung
                </v-card-title>
                
                <v-card-text class="pt-4 px-4">
                    <div class="text-center my-4">
                        <v-avatar size="80" class="warning lighten-4">
                            <v-icon size="48" color="warning darken-2">mdi-cart-outline</v-icon>
                        </v-avatar>
                    </div>
                    
                    <p class="text-h6 text-center mb-3">
                        Achtung: Multiwarenkorb erkannt
                    </p>
                    
                    <p class="text-body-1 text-center mb-4">
                        Diese Bestellung enthält mehrere Produkte aus verschiedenen Warenkörben, ist aber für den Versand bereit.
                    </p>
                    
                    <v-alert
                        dense
                        type="info"
                        outlined
                        class="mb-0"
                    >
                        Bitte stellen Sie sicher, dass alle Produkte vollständig sind, bevor Sie fortfahren.
                    </v-alert>
                </v-card-text>
                
                <v-divider></v-divider>
                
                <v-card-actions class="pa-4">
                    <v-spacer></v-spacer>
                    <v-btn 
                        color="grey darken-1" 
                        text
                        @click="dialogs.multicartButReadyWarning = false"
                    >
                        Ignorieren
                    </v-btn>
                    <v-btn 
                        color="warning"
                        depressed
                        @click="dialogs.multicartButReadyWarning = false"
                    >
                        <v-icon left small>mdi-check</v-icon>
                        Verstanden
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Warranty Modal -->
        <v-dialog v-model="dialogs.warranty" max-width="500" persistent :retain-focus="false">
            <v-card>
                <v-card-title class="warning white--text">
                    <v-icon left color="white">mdi-alert-circle</v-icon>
                    Reklamation
                </v-card-title>
                
                <v-card-text class="pa-4">
                    <div class="d-flex align-center my-3">
                        <v-icon color="warning" class="mr-3">mdi-clipboard-text</v-icon>
                        <div>
                            <p class="mb-0 font-weight-medium">Reklamationsgrund:</p>
                            <p class="mb-0">{{ currentItem.warrantyReason }}</p>
                        </div>
                    </div>
                </v-card-text>
                
                <v-divider></v-divider>
                
                <v-card-actions class="pa-3">
                    <v-spacer></v-spacer>
                    <v-btn 
                        color="primary" 
                        @click="dialogs.warranty = false"
                    >
                        Verstanden
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <!-- Multicart Shopping List Modal - MOVED TO BOTTOM TO APPEAR ON TOP -->
        <v-dialog v-model="dialogs.multicartShoppingList" max-width="700px" :retain-focus="false">
            <v-card>
                <v-card-title class="text-h5 bg-warning">
                    <v-icon class="mr-2">mdi-cart-arrow-down</v-icon>
                    Es befinden sich Bestellungen im Warenkorbregal
                </v-card-title>

                <v-card-text class="pa-4">
                    <div v-if="multiCartItems.length > 0">
                        <v-card 
                            v-for="item in multiCartItems" 
                            :key="item.orderId"
                            class="mb-4 order-card"
                            variant="outlined"
                            elevation="1"
                        >
                            <v-card-title class="d-flex align-center py-2 px-4 bg-secondary text-white">
                                <v-icon class="mr-2">mdi-cart</v-icon>
                                <span class="font-weight-bold">Bestellung #{{ item.orderId }}</span>
                                <v-chip class="ml-auto" color="warning" size="small">
                                    {{ item.products.length }} Artikel
                                </v-chip>
                            </v-card-title>
                            
                            <v-card-text class="px-0 py-2">
                                <!-- Group products by slot for better organization -->
                                <div 
                                    v-for="(shelfGroup, slot) in groupProductsBySlot(item.products)" 
                                    :key="slot"
                                    class="pa-2"
                                >
                                    <v-sheet
                                        class="px-3 py-2 mb-2 rounded d-flex align-center shelf-header"
                                        :color="getShelfColor(slot)"
                                        dark
                                        @click="selectShelf(slot)"
                                        :class="{'selected-shelf': selectedCartShelf === slot}"
                                    >
                                        <v-icon class="mr-2">mdi-shelf</v-icon>
                                        <span class="text-h6">Regal {{ slot }}</span>
                                        <v-chip class="ml-auto" size="small" variant="outlined">
                                            {{ shelfGroup.length }} Produkte
                                        </v-chip>
                                    </v-sheet>
                                    
                                    <v-list density="compact" class="products-list">
                                        <v-list-item
                                            v-for="(product, index) in shelfGroup"
                                            :key="index"
                                            class="product-item"
                                            :class="{
                                                'bg-grey-lighten-4': index % 2 === 0,
                                                'product-got-it': isProductMarked(`${item.orderId}_${product.sku}_${index}`)
                                            }"
                                        >                                            
                                            <v-list-item-title>
                                                <div class="d-flex align-center justify-space-between w-100">
                                                    <div class="d-flex align-center flex-grow-1 mr-2">
                                                        <span class="font-weight-bold">{{ product.qty }}x</span>
                                                        <span :class="{'text-decoration-line-through': isProductMarked(`${item.orderId}_${product.sku}_${index}`), 'ml-2 text-truncate': true}">
                                                            {{ product.sku }}
                                                        </span>
                                                    </div>

                                                    <v-checkbox
                                                        :input-value="isProductMarked(`${item.orderId}_${product.sku}_${index}`)"
                                                        @change="toggleProductMarked(`${item.orderId}_${product.sku}_${index}`)"
                                                        color="success"
                                                        dense
                                                        hide-details
                                                        class="flex-shrink-0 ma-0 pa-0"
                                                    ></v-checkbox>
                                                </div>
                                            </v-list-item-title>
                                            
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </v-card-text>
                        </v-card>
                    </div>
                    
                    <v-alert
                        v-else
                        type="info"
                        class="mt-4"
                        text="Keine Produkte im Warenkorbregal gefunden."
                    ></v-alert>
                    
                    
                </v-card-text>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                        color="warning" 
                        @click="dialogs.multicartShoppingList = false"
                    >
                        Bestätigen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style scoped>
.selected-shelf {
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
    transform: scale(1.02);
    transition: all 0.2s ease;
}

.products-list {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: #f9f9f9;
}

.product-item {
    transition: all 0.2s ease;
    cursor: pointer;
}

.product-item:hover {
    background-color: rgba(0, 0, 0, 0.05) !important;
}

.product-got-it {
    background-color: rgba(76, 175, 80, 0.1) !important;
}

.order-card {
    transition: box-shadow 0.2s ease;
}

.order-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
}

.shelf-header {
    cursor: pointer;
}

.not-ready-products {
    border: 1px solid rgba(0, 0, 0, 0.1);
    max-height: 250px;
    overflow-y: auto;
}

.action-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.action-button {
    flex-shrink: 0;
    min-width: auto;
}

/* Increase z-index for the multicart shopping list dialog */
::v-deep .multicart-shopping-list-dialog {
    z-index: 1000 !important; /* Higher than default Vuetify dialog z-index */
}

.engraving-product-list {
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 4px;
    max-height: 400px;
    overflow-y: auto;
}

.engraving-product-item {
    border-bottom: 1px solid rgba(0,0,0,0.05);
    transition: background-color 0.2s ease;
}

.engraving-product-item:hover {
    background-color: rgba(0,0,0,0.03);
}

.selected-product {
    background-color: rgba(76, 175, 80, 0.1) !important;
}

.personalization-details {
    background-color: #f8f8f8;
    border-left: 3px solid #e0e0e0;
    padding: 4px 8px;
    margin-top: 8px;
    border-radius: 0 4px 4px 0;
}

.attribute-item {
    font-size: 12px;
    margin-bottom: 2px;
    display: flex;
}

.attribute-name {
    font-weight: 500;
    min-width: 60px;
    color: #616161;
}

.attribute-value {
    color: #212121;
}

.error-details-form {
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
}

.problem-dialog-card {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.sticky-header-container {
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.sticky-header {
    position: relative;
    z-index: 10;
}

.sticky-tabs {
    position: relative;
    z-index: 9;
}

.sticky-footer {
    position: sticky;
    bottom: 0;
    background-color: white;
    z-index: 10;
    padding: 12px 16px;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
}

.enhanced-product-list {
    border-radius: 8px;
    border: 1px solid rgba(0,0,0,0.1);
    background-color: #f9f9f9;
    overflow-y: visible;
    padding: 8px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.05);
}

.product-item {
    border: 1px solid rgba(0,0,0,0.05);
    border-radius: 8px !important;
    margin-bottom: 12px;
    transition: all 0.2s ease;
    background-color: white;
}

.product-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.08);
}

.selected-product {
    background-color: rgba(76, 175, 80, 0.1) !important;
    border-left: 4px solid #4CAF50 !important;
}

.personalization-details {
    background-color: #f8f8f8;
    border-left: 3px solid #e0e0e0;
    padding: 12px;
    margin-top: 12px;
    border-radius: 0 4px 4px 0;
}

.attribute-table {
    background-color: transparent !important;
    border-radius: 4px;
}

.attribute-name {
    font-weight: 500;
    min-width: 100px;
    color: #616161;
    font-size: 13px;
}

.attribute-value {
    color: #212121;
    font-weight: 400;
    font-size: 13px;
}

.image-attributes {
    margin-top: 16px;
}

.image-card {
    border-radius: 8px;
    overflow: hidden;
    margin-right: 8px;
    margin-bottom: 8px;
    transition: all 0.2s ease;
}

.gap-3 {
    gap: 12px;
}

.order-info-alert {
    flex-grow: 1;
    margin-right: 16px;
}

.product-grid {
    margin: 0 -8px;
}

.product-card {
    height: 100%;
    transition: all 0.2s ease;
    border-radius: 8px;
    border-left: 3px solid transparent;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}

.product-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.1) !important;
}

.selected-product-card {
    border-left: 3px solid #4CAF50;
    background-color: rgba(76, 175, 80, 0.05) !important;
    box-shadow: 0 3px 8px rgba(76, 175, 80, 0.2) !important;
}

.product-header {
    border-bottom: 1px solid rgba(0,0,0,0.05);
    padding-bottom: 8px;
}

.product-title {
    font-size: 16px;
    line-height: 1.4;
    flex-grow: 1;
}

.attributes-preview {
    background-color: #f9f9f9;
    border-radius: 4px;
    padding: 8px;
    font-size: 13px;
    max-height: 120px;
    overflow: hidden;
}

.attribute-mini-list {
    background-color: transparent !important;
}

.image-preview {
    position: relative;
    width: 100%;
    height: 80px;
    overflow: hidden;
    border-radius: 4px;
    background-color: #f5f5f5;
}

.preview-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.image-count {
    position: absolute;
    bottom: 4px;
    right: 4px;
    background-color: rgba(0,0,0,0.6);
    color: white;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 10px;
    font-weight: bold;
}

.selection-counter {
    margin-left: 8px;
}

.error-details-form {
    background-color: white;
    border: 1px solid rgba(0,0,0,0.09);
}

.product-list {
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid rgba(0,0,0,0.09);
}

.product-list-item {
    border-bottom: 1px solid rgba(0,0,0,0.06);
    transition: all 0.2s ease;
    padding: 16px;
    margin-bottom: 4px;
    position: relative;
}

.product-list-item:hover {
    background-color: rgba(0,0,0,0.02);
}

.selected-product-list-item {
    background-color: rgba(76, 175, 80, 0.05) !important;
    border-left: 4px solid #4CAF50;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.selected-product-list-item::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 24px 24px 0;
    border-color: transparent #4CAF50 transparent transparent;
}

.product-sku {
    font-family: monospace;
    font-size: 14px;
    padding: 2px 6px;
    background-color: #f5f5f5;
    border-radius: 4px;
}

.product-image {
    position: relative;
    min-width: 120px;
}

.image-badge {
    position: absolute;
    bottom: 8px;
    right: 8px;
    background-color: rgba(0,0,0,0.7);
    color: white;
    border-radius: 4px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: bold;
}

.product-info {
    padding-left: 12px;
}

.attributes-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 160px;
    overflow-y: auto;
    padding: 6px 8px 6px 0;
}

.attribute-row {
    display: flex;
    font-size: 14px;
    line-height: 1.4;
    padding: 4px 0;
    border-bottom: 1px dashed rgba(0,0,0,0.05);
}

.attribute-label {
    font-weight: 500;
    color: #424242;
    min-width: 120px;
    margin-right: 12px;
}

.attribute-value {
    color: #212121;
    word-break: break-word;
    font-size: 15px;
}

.no-attributes {
    font-style: italic;
    font-size: 14px;
    padding: 8px 0;
}

.error-fields {
    display: flex;
    flex-grow: 1;
    max-width: 600px;
}

.error-field {
    flex: 1;
}

.selection-checkbox {
    transform: scale(1.4);
    margin-right: 8px;
    margin-left: 8px;
}

.border-top {
    border-top: 1px dashed rgba(0,0,0,0.1);
}

.error-reason-inputs {
    background-color: rgba(244, 67, 54, 0.05);
    border-radius: 4px;
    padding: 12px;
    margin-top: 12px;
}
</style>