import Api from '@/services/Api'

export default {
  getAllRules() {
    return Api().get('shipping-rules')
  },

  getActiveRules() {
    return Api().get('shipping-rules/active')
  },

  getRuleById(id) {
    return Api().get(`shipping-rules/${id}`)
  },

  createRule(rule) {
    return Api().post('shipping-rules', rule)
  },

  updateRule(id, rule) {
    return Api().put(`shipping-rules/${id}`, rule)
  },

  deleteRule(id) {
    return Api().delete(`shipping-rules/${id}`)
  }
}